import React, { Component } from 'react'
import validator from 'validator';

class TextAreaInput extends Component {
    constructor() {
        super()
        this.state = {
            focused: '',
            val: ''
        }
    }
    componentDidMount(){
        const {value, permission, state_name} = this.props
        this.setState({
            val: value
        })
        if(permission){
            this.props.updateStateOnLoad(state_name, true)
        }
    }

    componentWillUnmount(){
        const {state_name, permission} = this.props
        this.props.setError(state_name, false)
        if(permission){
            this.props.updateStateOnLoad(state_name, false)
        }
}
    handleOnBlur = () => {
        const { value, state_name, index } = this.props
        const { val } = this.state
        this.setState({
            focused: false
        })
        if (!val) {
            this.props.setError(state_name, true, index)
        }
        if (state_name == 'member_email') {
            let check = validator.isEmail(val)
            if (!check) {
                this.props.setError(state_name, true, index)
            }
        }
        this.handleChenge(val)
    }
    
    handleOnFocus = () => {
        const { value, state_name, index } = this.props
        this.setState({ focused: true, val: value })
        this.props.setError(state_name, false, index)

    }

    handleChenge = (value) => {
        const { index, state_name } = this.props
        if (index >= 0) {
            this.props.updateForm(state_name, value, index)
        }
        else {
            this.props.updateForm(state_name, value)
        }


    }
    render() {
        const { focused, val } = this.state
        const { name, error, error_msg } = this.props
        return (
            <div className={val || focused ? 
                'regitser__text__area common__input__class input__box__new__active' :
                'regitser__text__area common__input__class'}>
                <div>
                    <h3 className='input__name'>{name}</h3>
                    <textarea
                        onChange={(e) => this.setState({ val: e.target.value })}

                        onFocus={() => this.handleOnFocus()}
                        onBlur={() => this.handleOnBlur()}
                        value={val ? val : ''}
                        className={focused ? 'reg__input reg__input__focused' : 'reg__input'} />
                </div>
                {error ? <p className='register__error'>{error_msg}</p> : ''}
            </div>
        )
    }
}

export default TextAreaInput