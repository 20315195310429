import React, { Component } from 'react'
import {fabric} from 'fabric'
import axios from 'axios'
import { dataURItoBlob } from '../../../tools/data_url'

class Signature extends Component {
    constructor(props) {
        super(props)
        this.thing = React.createRef();
        this.state = {
            toDraw: false,
            drawPattern : 0,
            typeOf: 'parking'
        }
    }
    componentDidMount() {
        const {state_name, permission, index} = this.props
        const canvas = new fabric.Canvas(this.props.name)
        if(permission){
            this.props.updateStateOnLoad(state_name, true)
        }
        canvas.selection = false
        canvas.isDrawingMode = true
        canvas.freeDrawingBrush.width = 5
        canvas.freeDrawingBrush.color = '#202020'
        canvas.selection = false
        canvas.isDrawingMode = true
        this.setState({ canvas })
        canvas.on('mouse:up', (o) => {
            // this.setState({ signature_error: false, canvas_lines: canvas.getObjects() });
            // this.props.updateForm('canvas_lines', canvas.getObjects())
            this.props.updateForm(this.props.state_name, canvas.getObjects())
            this.props.setError(state_name, false, index)
        })
    }
    componentWillUnmount(){
        const {state_name, permission} = this.props
        this.props.setError(state_name, false)
        if(permission) {
            this.props.updateStateOnLoad(state_name, false)
        }
    }   
    async revert() {
        // const file = dataURItoBlob((this.state.canvas.toDataURL('image/png')))
        // const formData = new FormData
        // formData.append('signature', file, )
        // formData.append('member_id_number', '123131')
        // const res = await axios.post('https://reg-api.krembo.org.il/files/signature', formData)
        this.state.canvas.clear()
        this.props.updateForm(this.props.state_name, undefined)

    }

    render() {
        const { height, width, error_msg, error } = this.props
        const { toDraw } = this.state
        console.log(this.props)
        return (
            <div style={{marginBottom: error ? '22px' : '10px', marginTop: '30px'}} ref={this.thing} className="signature__fabric__container">
                <canvas 
                    width={500}
                    height={200}
                    className="canvas"
                    id={this.props.name}
                >
                </canvas>
                <div className="signature__line" />
                <p onClick={() => this.revert()}>איפוס חתימה</p>
                <br/>
                {error ?  <p style={{marginBottom: '30px'}} className='register__error'>{error_msg}</p> : ''}
            </div>

        )
    }
}
export default Signature
