import React, { Component } from 'react'



import PersonalForm from './personal_parts/PersonalForm'


class Personal extends Component {
    constructor() {
        super()
        this.state = {
            errors_arr: [],
            brother_arr:[],
            error_checked: [],
            errors: []
        }
    }

    resetValidation = () => {
        this.setState({
            validate_form: false
        })
    }

    componentDidUpdate(nextProps, prevState) {
        if(this.props.brothers.length === this.state.errors.length) {
            if(this.state.errors.reduce((a, b) => a + b, 0) === 0) {
                this.setState({ errors: [] })
                this.props.setForm('permissions')
            } else {
                this.setState({ errors: [] })
            }
        } else if(this.state.errors.length > 0) {
            this.setState({ errors: [] })
        }
    }

    sendErrors = (errors_recieved, index) => {
        const { brothers } = this.props
        const { errors_arr } = this.state
        errors_arr[index] = errors_recieved
        this.setState({
            errors_arr
        })
        let error_count = errors_arr.reduce(function (a, b) { return a + b })
        // if (error_count === 0) {
        //     // this.props.setForm('permissions')
        // } else {
            
        // }
        this.setState(prevState => ({errors: [...prevState.errors, error_count]}))
    }

    render() {
        const { brothers , PERSONAL, branch_discount, discount_last, movil_discount} = this.props
        const { validate_form } = this.state
        return (
            <div className='personal__form__wrapper'>
                {
                    brothers && brothers.length > 0 ?
                        brothers.map((member, i) =>
                            <PersonalForm
                                member_id={i}
                                movil_discount={movil_discount && i === 0}
                                updateBrotherByIndex={this.props.updateBrotherByIndex}
                                member={member}
                                brother={brothers[i]}
                                validate_form={validate_form}
                                resetValidation={this.resetValidation}
                                removeBrother={this.props.removeBrother}
                                sendErrors={this.sendErrors}
                                branch_discount = {branch_discount}
                                discount_last = {discount_last}
                            />
                        ) : ''}
                <span className='add__brother__container__new'>
                    <button
                        className='add__brother__btn'
                        onClick={() => this.props.addBrother()}
                        type='button'>
                        <h3>{PERSONAL.ADD}</h3>
                    </button>
                    <button type="submit"
                        onClick={() => this.setState({
                            validate_form: true
                        })}
                        className='register__submit'
                    >
                        <h4>{PERSONAL.NEXT}</h4>
                        <h3>←</h3>
                    </button>
                </span>
            </div>
            )
    }
}

export default Personal