import React, { Component } from 'react'
import Radio from '@material-ui/core/Radio';

class RadioInput extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
        const { check, options, state_name, index , permission} = this.props
        if (check && index >= 0) {
            // this.props.updateForm(state_name, options[0], index)
        }
        if (check && !index) {
            // this.props.updateForm(state_name, options[0])
        }
        if(permission){
           
            this.props.updateStateOnLoad(state_name, true)
        }
    }

    handleChange = (n) => {
        const {index, state_name} = this.props
        if(index >= 0){
            this.props.updateForm(state_name, n, index)
        }else{
            this.props.updateForm(state_name, n)
        }
        this.props.setError(state_name, false)
    }
    componentWillUnmount() {
        const { state_name, permission } = this.props
        this.props.setError(state_name, false)
        if(permission){
            this.props.updateStateOnLoad(state_name, false)
        }
    }


    render() {
        const { state_name, name, value, options, render_onload, data_to_render, selected, error_msg, error } = this.props
        return (
            <div className='radio__container'>
                <h3>{name}</h3>
                <ul>
                    {options && options.length >= 1 ?
                        options.map((n, i) =>
                            <li key={i} >
                                <span>
                                    <Radio
                                        checked={value === n}
                                        onChange={() => this.handleChange(n) }
                                    />
                                </span>
                                <h3> {n}</h3>
                            </li>)
                        : null}
                </ul>
                {error ? <p className='register__error'>{error_msg}</p> : ''}
                {
                    render_onload ? 
                    data_to_render
                    :
                    data_to_render && selected == value ?
                        data_to_render
                        : ''
                }
                 
            </div>
        )
    }
}

export default RadioInput