import React, { Component } from 'react'
import validator from 'validator';

class RegularInput extends Component {
    constructor() {
        super()
        this.state = {
            focused: '',
            val: ''
        }
    }
    componentDidMount() {
        const { value, permission, state_name } = this.props
        this.setState({
            val: value
        })
        if(permission){
            this.props.updateStateOnLoad(state_name, true)
        }
    }
    handleOnBlur = () => {
        const { value, state_name, index, validation, not_required } = this.props
        const { val } = this.state
        this.setState({
            focused: false
        })
        if(!not_required) {
            let check = this.validateInput(validation, val)
            if (!val) {
                this.props.setError(state_name, true, index)
            }
                if(!check && index >= 0){
                    this.props.setError(state_name, true, index)
                }else if(!check && !index){
                    this.props.setError(state_name, true)
                }
        }

        this.handleChenge(val)
    }


    validateInput = (type, value) => {
        if(type === 'email'){
            if(validator.isEmail(value)){
                return true
            }
            else{
                return false
            }
        }
    }



    handleOnFocus = () => {
        const { value, state_name, index } = this.props
        this.setState({ focused: true, val: value })
        this.props.setError(state_name, false, index)

    }

    handleChenge = (value) => {
        const { index, state_name } = this.props
        if (index >= 0) {
            this.props.updateForm(state_name, value, index)
        }
        else {
            this.props.updateForm(state_name, value)
        }


    }

    componentWillUnmount() {
        const { state_name, permission,  } = this.props
        this.props.setError(state_name, false)
        if(permission){
            this.props.updateStateOnLoad(state_name, false)
        }
    }

    render() {
        const { focused, val } = this.state
        const { name, error, error_msg, validation_error, validation, no_number } = this.props
    
        return (
            <div className={val || focused ? 'input__box__new common__input__class input__box__new__active' :
                'input__box__new common__input__class'}>
                <span>
                    <h3 className='input__name'>{name}</h3>
                    <input type='text'
                        onChange={(e) => this.setState({ val: no_number ? e.target.value.replace(/[0-9]/g, '')  : e.target.value })}

                        onFocus={() => this.handleOnFocus()}
                        onBlur={() => this.handleOnBlur()}
                        value={val}
                        className={focused ? 'reg__input reg__input__focused' : 'reg__input'} />
                </span>
                {error && !val? <p className='register__error'>{error_msg}</p> : 
                error && val ? <p className='register__error'>{validation_error}</p> :
                ''}
            </div>
        )
    }
}

export default RegularInput