import React, { Component } from 'react'
import ShirtImg from '../images/shirt.svg'
import RegImg from '../images/registration.svg'
import Loader from '../../Loader/Loader'
import { API, determinePriceByCityName } from '../../../tools/keys'
import axios from 'axios'
import { setCookie } from '../../../tools/cookies'

class Payment extends Component {
    constructor() {
        super()
        this.state = {
            from: 200,
            to: 300,
            loading: false,
            shirt_default_price: 50,
            default_registration_price: 425
        }
    }
    
    
    componentDidMount() {
        console.log(this.props)
        const { brothers} = this.props
        if (brothers && brothers.length > 0) {
            this.calculate(brothers)
        }
        
    }
    submitPayment =  async () => {
        const {new_total, after_coupon_total, with_coupon,used_coupon} = this.state
        const {brothers, discount_last, movil_discount, branch_discount} = this.props
        const name = brothers[0].first_parent_name
        const phone = brothers[0].first_parent_phone
        const email = brothers[0].first_parent_email
        const total = after_coupon_total ? after_coupon_total : new_total ? new_total : this.state.total
        const ids = brothers.map(m => m.member_id_number)
        const project = "הרשמה דרך האתר"
        const notes = `${ids.map(m => m)} הרשמה דרך האתר`
        const city = brothers[0].member_city
        const address = brothers[0].member_street
        const id = brothers[0].member_id_number
        const subscribed = false
        const registration = true
        brothers.forEach(val => {
            if(val.member_position_additional){
                val.member_position = val.member_position_additional
            }
        })
        console.log(used_coupon, 'hellasda')
        const res = await axios.post(`${API}/bin/create?manager=${used_coupon && used_coupon.includes('manager')}&type=member&branch_discount=${branch_discount}&length=${brothers.length}&movil=${used_coupon || movil_discount ? movil_discount || used_coupon.includes('movil') ? true : 'undefined' : 'undefined'}&discount_june=${discount_last}&used_coupon=${used_coupon || movil_discount}`, brothers)
        const {ok, result} = res.data
        if (ok) {
            console.log(result ,'hello')
            localStorage.setItem('member_info', result)
            axios.post(`https://api.krembo.org.il/donate`, {
                name, phone, email, total, notes, project, city, address, id, subscribed, registration
            }).then(res => {
                const { result, ok } = res.data
                if (ok) {
                    this.props.cancelReload()
                    window.location.replace(result);
                } else {
                    this.setState({
                        sending_error: true
                    })
                }
            })
        } 

    }
    calculate = (arr) => {
        
        const { default_registration_price, shirt_default_price } = this.state
        const {coupon_code, discount_last} = this.props
        let total
        let discount = 0
        let registration_price
        let registration_arr
        let shirt_cost
        let shirt_amount
        let cities = []
        let brothers_amount = arr.length
        registration_arr = arr.map(val => {
            console.log(val, 'hlelaklsa')
            return {
                branch: val.branch ? val.branch : 'netanya',
                price: discount_last ? 50 : val.registration_price || val.registration_price === 0 ?
                    val.registration_price :
                    default_registration_price,
                shirt: val.shirt_size ? 1 : 0
            }
        })
        cities  = registration_arr.map(m => m.city)
        var counts = {};
       cities.forEach(function(x) {
            counts[x] = (counts[x] || 0)+1});
        let test = 
      
        registration_price = registration_arr.reduce((acc, pilot) => acc + pilot.price, 0);
        shirt_cost = registration_arr.reduce((acc, pilot) => acc + pilot.shirt, 0) * shirt_default_price
        shirt_amount = registration_arr.reduce((acc, pilot) => acc + pilot.shirt, 0)
        if (arr.length > 1) {
           
            discount = registration_price * 0.1
            registration_price = registration_price - discount
          
        }
        console.log(registration_price, 'registration price')
        total = shirt_cost + registration_price
        this.setState({
            total,
            discount,
            registration_price,
            shirt_cost,
            brothers_amount,
            registration_arr,
            shirt_amount
        })
   
        if(coupon_code ){
            this.setState({
                hide_coupon_input:true
            })
            this.applyCoupon(coupon_code, registration_arr, shirt_cost)
        }else{
           this.setState({
            page_loaded:true
           })
        }
    }



    applyCoupon = async (saved_coupon, reg_arr, shirt) => {
        const { coupon_code, registration_arr, shirt_cost } = this.state
        const {brothers} = this.props
        let new_registration_arr
        let coupon
        let new_shirt
            if(saved_coupon){
                 coupon = saved_coupon
                 new_registration_arr = reg_arr
                 new_shirt = shirt
            }else{
                coupon = coupon_code
                new_registration_arr = registration_arr
                new_shirt = shirt_cost
            }
        this.setState({
            coupon_loading: true
        })
        let arr =[ ]
        let used_coupon
        let discount
        let total
        let status_arr = []
        const body = {signup_total: new_registration_arr[0].price, code: coupon, member_id: brothers.map(n => n.member_id_number)}
        let res = await axios.post(API + '/member/discountcode', body)
        console.log(res)
        status_arr = [...status_arr, res.data.ok]
       
    
     if(!status_arr.includes(false)) {
        const is_movil = coupon.includes('movil')
        if(is_movil) {
            used_coupon = coupon
            new_registration_arr[0].price = res.data.result
            let registration_price = new_registration_arr.reduce((acc, pilot) => acc + pilot.price, 0);
            if (brothers.length > 1) {
                discount = registration_price * 0.1
                registration_price = registration_price - discount
     
            }
             total = new_shirt + registration_price
           
        } else if(coupon.includes('manager'))  {
            used_coupon = coupon
            new_registration_arr[0].price = res.data.result
            let registration_price = new_registration_arr.reduce((acc, pilot) => acc + pilot.price, 0);
            if (brothers.length > 1) {
                discount = registration_price * 0.1
                registration_price = registration_price - discount
     
            }
             total = new_shirt + registration_price
        }
        else {
            used_coupon = coupon
            let registration_price = new_registration_arr.reduce((acc, pilot) => acc + pilot.price, 0);

            if (brothers.length > 1) {
                discount = registration_price * 0.1 + res.data.result
                console.log(discount)
                registration_price = registration_price - discount
            } else {
                new_registration_arr[0].price = res.data.result
                registration_price = new_registration_arr.reduce((acc, pilot) => acc + pilot.price, 0);
            }
            total = new_shirt + registration_price
        }

       this.setState({
           discount,
           used_coupon,
           after_coupon_total:total,
           registration_arr:new_registration_arr,
           page_loaded:true,
           coupon_loading:false,
           hide_coupon_input:true,
           with_coupon:true
       })
     }else{
         this.setState({
            coupon_loading:false,
            coupon_error:true
         })

     }
       
    
    }

    render() {
        const {
            from,
            shirt_default_price,
            coupon_code,
            coupon_active,
            registration_arr,
            shirt_cost,
            discount,
            total,
            coupon_error,
            page_loaded,
            coupon_loading,
            loading,
            hide_coupon_input,
            after_coupon_total,
            shirt_amount
        } = this.state
        console.log(this.props,'hello')
        const {PAYMENT, discount_last} = this.props
        return (
            <div className='payment__register'>
                
              {page_loaded ?   <section className='payment__list'>
                    <h2>{PAYMENT.PAYMENT_H1_2}</h2>
                    {
                        registration_arr && registration_arr.length > 0 ?
                            registration_arr.map((m, i) => {
                                m.price = discount_last ? 50 : m.price
                                return <span>
                                <h3><img src={RegImg} alt="paper" />הרשמה סניף {m.branch} </h3>
                                <h4>{`₪${m.price}`}</h4>
                            </span>
                            }

                                )
                            : ''
                    }
 
                    <span className='payment__with__border'>
                        <h3><img src={ShirtImg} alt="shirt" className="shirt_margin__register__new" />{PAYMENT.SHIRT_INVITE} </h3>
                        <h4 style={{direction:'ltr'}}>{`  ₪${shirt_default_price} x ${shirt_amount} `}</h4>
                    </span>
                    {
                        hide_coupon_input
                        ?
                        <div className={'coupon__box'}>
                        <h3>{PAYMENT.COUPON}</h3>
                          <aside
                              className={coupon_active?
                                  'payment__coupon__input coupon__active' :
                                  'payment__coupon__input'}
                          >
                              <h4>התקבל</h4>
                              <label >{PAYMENT.ENTER_COUPON}</label>
                              <p className={coupon_error ?
                                  'coupon_error coupon_error__active' :
                                  'coupon_error'}>{PAYMENT.COUPON_ERROR}</p>
                          </aside>
                        </div>
                        :
                        <div className={'coupon__box'}>
                        <h3>{PAYMENT.COUPON}</h3>
                          <aside
                              className={coupon_active?
                                  'payment__coupon__input coupon__active' :
                                  'payment__coupon__input'}
                          >
                              <input type="text"
                                  onChange={(e) =>
                                      { this.setState({ coupon_code: e.target.value })
                              this.props.updateCoupon('coupon_code', e.target.value)
                              }}
                                  onFocus={() => this.setState({ coupon_active: true, coupon_error: false })}
                                  onBlur={() => this.setState({
                                      coupon_active: coupon_code
                                  })}
                                  value={coupon_code}
                              />
                              <button
                                  className={coupon_code ?
                                      'coupon__submit coupon__submit__active' :
                                      'coupon__submit'}
                                  onClick={() => this.applyCoupon()}>{PAYMENT.SEND}</button>
                              <label >{PAYMENT.ENTER_COUPON}</label>
                              <p className={coupon_error ?
                                  'coupon_error coupon_error__active' :
                                  'coupon_error'}>{PAYMENT.COUPON_ERROR}</p>
                          </aside>
                        </div>
                    }

                      <span>
                        <h3>{PAYMENT.DISCOUNT}</h3>
                        <h4>{discount ? `10%` : ''}</h4>
                    </span>
                    <span>
                        <h3>{PAYMENT.TOTAL}</h3>
                        <h4 style={{textDecoration:hide_coupon_input ? 'line-through' :''}}>{total ? `₪${total}` : 0}</h4>
                    </span>


                    <span>

                        <div className={hide_coupon_input?'hidden__coupon__discount hidden__coupon__discount__active':
                    'hidden__coupon__discount'}>
                        <h3>{PAYMENT.AFTER_DISCOUNT}</h3>
                        <h4>{`₪${after_coupon_total}`}</h4>
                        </div>
                    </span>
                    {
                        loading ?
                        <div className="register__new__loader">
                            <Loader />
                        </div>
                        : 
                        <button className='payment__submit' onClick = {() =>{
                            this.submitPayment()
                            this.setState({ loading: true })
                        }}>{ PAYMENT.PAY}</button>
                    }
                    
                </section> : ''}
            </div >
        )
    }
}

export default Payment