import React, { Component } from 'react'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { API } from '../../../tools/keys'
const FILE_LIMIT = 25000000





class UploadDoctorTestimony extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploading: false,
            load_bar: 0
        }
    }
    componentDidMount() {
        
    }
    async onDrop(files) {
        const { 
            member_id_number
        } = this.props
        this.setState({ 
            warning: '', 
            done_upload: '', 
            load_bar: 0 
        })
        const { 
            size,
            type
        } = files[0]

        if(!member_id_number) {
            this.setState({ warning: 'אנא מלא את מספר תעודת הזהות של החניך' })
        }
        else if(size >= FILE_LIMIT) {
            this.setState({ warning: 'הקובץ שוקל מעל ל25 מגה בייטים' })
        } else if(!type) {
            this.setState({ warning: '' })
        } else {
            console.log()
            this.setState({ uploading: true })
            const formData = new FormData()
            formData.append('document', files[0])
            formData.append('member_id_number', '13131419001')
            const config = {
                onUploadProgress: progressEvent => {
                    this.setState({ 
                        load_bar: (progressEvent.loaded/size)  * 100, 
                        load_size: progressEvent.loaded >= size ? size : progressEvent.loaded
                    })
                }
            }
            const res = await axios.post(`${API}/files/document`, formData, config)
            const { ok } = res.data
            if(ok) {
                this.setState({ done_upload: 'העלאה הושלמה' })
            } else {

            }
        }
      }
    render() {
        const { 
            load_bar, 
            uploading, 
            done_upload,
            warning
        } = this.state
        return (
            <div>
                <Dropzone className="upload__doctor__testimony__container" onDrop={this.onDrop.bind(this)}>
                    <div className="upload__testimony__here">
                        {
                            uploading
                            ?
                            <div 
                            style={{ width: `${done_upload ? 100 : load_bar >= 92 ? 92 : load_bar}%` }}
                            className="doctor__testimony__file__progress" >
                                <p>{done_upload ? done_upload : load_bar >= 92 ? `92%` : load_bar >= 25 ? `${parseInt(load_bar)}%` : ''}</p>
                            </div>
                            :
                            <p>הוסף הצהרת רופא כאן</p>
                        }
                    </div>
                    <div className="add_file__button">הוסף קובץ</div>

                </Dropzone>
                {
                    warning
                    ?
                    <p className="upload__doctor__testimony__warning">{warning}</p>
                    :
                    ''
                }
            </div>

        )
    }
}
export default UploadDoctorTestimony