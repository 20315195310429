import React, { Component } from 'react'
import cities from '../data/cities'

class InputSelect extends Component {
    constructor() {
        super()
        this.state = {
            focused: false
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }


    handleClickOutside(event) {
        const { value, filter_value, state_name, index } = this.props
        const {active} = this.state
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)  && active ) {
            this.setState({
                active: false
            })
            if (value !== filter_value) {
                this.selectCity(value)
            }
            if(!value){
                this.props.setError(state_name, true, index)

            }
            
        }
       
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    selectCity =async  (val) => {
        
        const { value, index, state_name, name, filter_value, filter_value_state_name } = this.props
      
        this.props.setError(state_name,false, index)
        if (index >= 0) {
            this.props.updateForm(state_name, val, index)
            this.props.updateForm(filter_value_state_name, val, index)
        } else {
           await  this.props.updateForm(state_name, val)
            this.props.updateForm(filter_value_state_name, val)
        }
        this.setState({
            active: false
        })

    }
    render() {
        const { value, index, name, filter_value, filter_value_state_name, error, error_msg, state_name, options } = this.props
        const { active } = this.state
       
        return (
            <div
                ref={this.setWrapperRef}
                className={active ?
                    'input__box__new input__select common__input__class input__box__new__active input__select__selected' :
                    value ? 'input__box__new input__select select__box common__input__class input__select__selected' :
                        'input__box__new common__input__class input__select'}>
                            
                <h3>{name}</h3>
                <div className='select__arrow'> </div>
                <input type="text"
                    value={active ? filter_value : value}
                    onClick={() => this.setState({
                        active: true
                    })}
                    onFocus = {() =>   this.props.setError(state_name, false, index)}
                    onChange={(e) => {
                        index >= 0 ?
                            this.props.updateForm(filter_value_state_name, e.target.value, index) :
                            this.props.updateForm(filter_value_state_name, e.target.value)
                    }}
                />
                <ul>{
                    options.filter(m => m.indexOf(filter_value) > -1).map((m, i) =>
                        <li key={i}
                            onClick={() => this.selectCity(m)}
                        >{m}</li>
                    )
                }</ul>
                {error ? <p className='register__error'>{error_msg}</p> : ''}
            </div>
        )
    }
}

export default InputSelect

