import React, { Component } from 'react'
import SelectedOne from '../../../images/register/sections_new/1_white.svg'
import SelectedTwo from '../../../images/register/sections_new/2_white.svg'
import SelectedThree from '../../../images/register/sections_new/3_white.svg'
import SelectedFour from '../../../images/register/sections_new/4_white.svg'

import AccessibleOne from '../../../images/register/sections_new/1_black.svg'
import AccessibleTwo from '../../../images/register/sections_new/2_black.svg'
import AccessibleThree from '../../../images/register/sections_new/3_black.svg'
import AccessibleFour from '../../../images/register/sections_new/4_black.svg'

import One from '../../../images/register/sections_new/1_gray.svg'
import Two from '../../../images/register/sections_new/2_gray.svg'
import Three from '../../../images/register/sections_new/3_gray.svg'
import Four from '../../../images/register/sections_new/4_gray.svg'

class RegisterNavbar extends Component {
    render() {
        const { selected, accessible, parents_approval_form } = this.props
        let nav = [
            {name:'פרטי  פעיל', section:'personal', img_selected: SelectedOne, img: One ,accessible: AccessibleOne},
            {name:'אישורים', section:'permissions', img_selected: SelectedTwo, img: Two ,accessible: AccessibleTwo},
            {name:'פרטי הורים', section:'parents', img_selected: SelectedThree, img: Three ,accessible: AccessibleThree},
            {name:'סיכום תשלום', section:'payment', img_selected: SelectedFour, img: Four ,accessible: AccessibleFour},
        ]
        if(parents_approval_form) nav.pop()
        return (
           <ul className='new__register__nav flex'>
              {
                  nav.map((m, i) => {
                    let accessible_click = accessible.filter(n => n === m.section).length > 0
                    return <li
                    className = {`
                    ${selected === m.section ? 'active__register__nav' :''}
                    ${accessible_click ? 'accessible__font__change': ''}
                    `}
                    key = {i}
                    onClick = {() => accessible_click ? this.props.setForm(m.section) : ''}
                    >
                        <img src={selected === m.section ? m.img_selected : accessible_click ? m.accessible : m.img}/>
                        {m.name}
                    </li>
                  }

                  )
              }
           </ul>
        )
    }
}

export default RegisterNavbar