import React, { Component } from 'react'
import { personal_data, EXTRA_SHIRT, SHIRT_SELECT } from '../../data/personal'
import SelectBox from '../../parts/SelectBox'
import { connect } from 'react-redux'
import * as actions from '../../../../actions/actions'
import NumberInput from '../../parts/NumberInput'
import RegularInput from '../../parts/RegularInput'
import RadioInput from '../../parts/RadioInput'
import ShirtSelect from '../../parts/ShirtSelect'
import PercentInput from '../../parts/PercentInput'
import InputSelect from '../../parts/InputSelect'
import moment from 'moment'

import { all_branches } from '../../../../tools/branch_parser'



class PersonalForm extends Component {
    constructor() {
        super()
        this.state = {
            validation_arr: []
        }
    }
    async   componentWillUnmount() {
        const { brother_info } = this.state
        const { member_id } = this.props
        await setTimeout(() => {
            if (brother_info) {
                brother_info['member_birth_date'] = this.createDate(brother_info.day, brother_info.month, brother_info.year)

                this.props.updateBrotherByIndex(brother_info, member_id)
            }

        }, 200);

    }
    validate = () => {


        const { brother_info, validation_arr } = this.state
        const { member_id } = this.props
        let data = personal_data


        let check = brother_info['member_position'] == 'חבר צוות מוביל' ||
            brother_info['member_position'] == 'חבר צוות מנהל'
        let errors = 0


        data.filter(m => m.required === true).forEach(element => {
            const { no_required_if } = element
            if (!brother_info[element.state_name] &&!(no_required_if && brother_info[no_required_if[0]] === no_required_if[1])) {
                errors = errors + 1
                let elem = `${element.state_name}_err`
                this.setState({
                    [elem]: true
                })
            }


        });
        if (check && !brother_info['member_position_additional']) {
            errors = errors + 1
            let elem = `member_position_additional_err`
            this.setState({
                [elem]: true
            })
        }
        const error = document.querySelectorAll('.register__error')
        let active_errors = ''
        if (error && error.length > 0) {
            error.forEach(val => {
                if (val.innerHTML.length > 0) {
                    active_errors = true
                }
            })

        }
        if (!active_errors) {
            this.props.sendErrors(errors, member_id)
        }



    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.validate_form) {
            this.props.resetValidation()

            this.validate()
        }
    }

    setError = (state_name, val, index) => {
        const { validation_arr } = this.state
        let elem = `${state_name}_err`
        this.setState({
            [elem]: val
        })
        let validation_name = `${state_name}_validation`

        let arr = validation_arr
        arr[validation_name] = val
        this.setState({
            validation_arr: arr
        })
    }


    componentDidMount() {
        const { brother } = this.props

        this.setState({
            brother_info: brother,
            loaded: true
        })
    }


    updateFormLocal = (name, value) => {
        const {branch_discount, discount_last, movil_discount} =this.props
        const { brother_info, index } = this.state
        let brother = brother_info
        if (brother) {
            let registration_price = 0
            if (name === 'branch') {
                if(discount_last) {
                    registration_price = 0
                } else if(movil_discount) {
                    registration_price = all_branches[value]
                    const calculate = all_branches[value] / 2
                    registration_price = registration_price <= 200 ? registration_price : calculate <= 200 ? 200 : calculate  < 250 ? 200 : 250
                }
                else if(branch_discount){
                    registration_price = all_branches[value] / 2
                }else{
                     registration_price = all_branches[value]
                }
              
                brother['registration_price'] = registration_price
            }
            if (name === 'member_position') {
                brother['member_position_additional'] = ''
            }
            if (name === 'another_shirt') {
                brother['shirt_size'] = ''
            }
            if (name === 'member_experience') {
                brother['shirt_size'] = ''
            }
            brother[name] = value
            console.log(brother)
            this.setState({
                brother_info: brother
            })
        }
    }

    createDate = (day, month, year) => {


        let elem
        switch (month) {
            case 'ינואר':
                elem = '01'
                break;
            case 'פברואר':
                elem = '02'
                break;
            case 'מרץ':
                elem = '03'
                break;
            case 'אפריל':
                elem = '04'
                break;
            case 'מאי':
                elem = '05'
                break;
            case 'יוני':
                elem = '06'
                break;
            case 'יולי':
                elem = '07'
                break;
            case 'אוגוסט':
                elem = '08'
                break;
            case 'ספטמבר':
                elem = '09'
                break;
            case 'אוקטובר':
                elem = '10'
                break;
            case 'נובמבר':
                elem = '11'
                break;
            case 'דצמבר':
                elem = '12'
                break;

            default:
                break;
        }

        const date = `${year}-${elem}-${day < 10 ? `0${day}` : day}T00:00`

        const new_date = moment(date).toISOString()
        return new_date

    }

    render() {

        const { language } = this.props.main

        const { member_id, member } = this.props

        const { loaded } = this.state
        console.log(personal_data, 'helasda')
        return (
            loaded ? <div
                className='personal__form'>
                {member_id >= 1 ?
                    <span className='extra__brother__header flex'>
                        <h1>{member['member_first_name'] ?
                            member['member_first_name'] :
                            language.PERSONAL.EXTRA_BROTHER}</h1>
                        <button
                            onClick={() => this.props.removeBrother(member_id)}
                        >{language.PERSONAL.REMOVE_BROTHER}</button>
                    </span>
                    : ''}
                <header className='register__header'>{language.PERSONAL.INFO}</header>

                <section className='gender__section flex'>
                    {personal_data.map((m, i) =>
                        i <= 5 ?
                            m.type === 'select' ?
                                <SelectBox
                                    options={language.PERSONAL[m.options]}
                                    value={member[m.value] ? member[m.value] : ''}
                                    state_name={m.state_name}
                                    updateForm={this.updateFormLocal}
                                    placeholder={language.PERSONAL[m.placeholder]}
                                    name={language.PERSONAL[m.name]}
                                    prefix={language.PERSONAL[m.prefix]}
                                    index={member_id}
                                    error={this.state[`${m.state_name}_err`]}
                                    error_msg={language.PERSONAL[m.error_msg]}
                                    setError={this.setError}

                                /> :

                                m.type === 'prefix_text' ?
                                     <h5 className='prefix__text'>{language.PERSONAL[m.prefix]}</h5>
                                :
                                m.type === 'number' ?
                                    <NumberInput
                                        name={language.PERSONAL[m.name]}
                                        updateForm={this.updateFormLocal}
                                        not_required={m.not_required}
                                        value={member[m.value] ? member[m.value] : ''}
                                        state_name={m.state_name}
                                        index={member_id}
                                        prefix={language.PERSONAL[m.prefix]}
                                        error={this.state[`${m.state_name}_err`]}
                                        error_msg={language.PERSONAL[m.error_msg]}
                                        setError={this.setError}
                                        validation={m.validation}
                                        validation_error={language.PERSONAL[m.validation_error]}
                                    /> : ''
                            : '')}
                </section>
                <section className='personal__main__flex'>
                    {personal_data.map((m, i) =>{
                        const pickupDetails = m.state_name === 'pickup_location' 
                        || m.state_name === 'contact_person' || m.state_name === 'contact_phone' || m.state_name === 'home_eta'
                        const specialOrClass = member.education_program === 'חינוך מיוחד' || member.education_program === 'כיתה קטנה בבי"ס כללי'
                        if(pickupDetails && !specialOrClass) {
                            return ''
                        }
                        else return i > 5 && i < 27 ?
                        m.type === 'select' ?
                            <SelectBox
                                options={language.PERSONAL[m.options] ? language.PERSONAL[m.options] : []}
                                value={member[m.value] ? member[m.value] : ''}
                                state_name={m.state_name}
                                updateForm={this.updateFormLocal}
                                placeholder={language.PERSONAL[m.placeholder]}
                                name={language.PERSONAL[m.name]}
                                no_required_if={m.no_required_if}
                                member={member}
                                index={member_id}
                                extra_info={m.extra_info}
                                error={this.state[`${m.state_name}_err`]}
                                error_msg={language.PERSONAL[m.error_msg]}
                                setError={this.setError}
                            /> :
                            m.type === 'select_position' && member[m.condition] == "חבר צוות מוביל" ?
                                <span className='lala'>
                                    <SelectBox
                                        options={language.PERSONAL[m.options[0]]}
                                        value={member[m.value] ? member[m.value] : ''}
                                        state_name={m.state_name}
                                        updateForm={this.updateFormLocal}
                                        placeholder={language.PERSONAL[m.placeholder]}
                                        name={language.PERSONAL[m.name]}
                                        setError={this.setError}
                                        index={member_id}
                                        extra_info={m.extra_info}
                                        error_msg={language.PERSONAL[m.error_msg]}
                                        error={this.state[`${m.state_name}_err`]}
                                        placeholder_value={member[m.placeholder_value] ? member[m.placeholder_value] : ''}
                                    />
                                </span> :
                                m.type === 'select_position' && member[m.condition] == "חבר צוות מנהל" ?
                                    <span className='lala'>
                                        <SelectBox
                                            class={true}
                                            options={language.PERSONAL[m.options[1]]}
                                            value={member[m.value] ? member[m.value] : ''}
                                            state_name={m.state_name}
                                            updateForm={this.updateFormLocal}
                                            extra_info={m.extra_info}
                                            placeholder={language.PERSONAL[m.placeholder]}
                                            name={language.PERSONAL[m.name]}
                                            index={member_id}
                                            setError={this.setError}
                                            error_msg={language.PERSONAL[m.error_msg]}
                                            error={this.state[`${m.state_name}_err`]}
                                            placeholder_value={member[m.placeholder_value] ? member[m.placeholder_value] : ''}
                                        />
                                    </span> :
                                    m.type === 'select_position' ?
                                        '':
                                        m.type === 'placeholder' ? 
                                            <div 
                                            id='placeholder__div'
                                            className='input__box__new select__box common__input__class'></div>
                                        :
                                        m.type === 'number' ?
                                            <NumberInput
                                                name={language.PERSONAL[m.name]}
                                                updateForm={this.updateFormLocal}
                                                value={member[m.value] ? member[m.value] : ''}
                                                state_name={m.state_name}
                                                index={member_id}
                                                extra_info={m.extra_info}
                                                error={this.state[`${m.state_name}_err`]}
                                                not_required={m.not_required}
                                                error_msg={language.PERSONAL[m.error_msg]}
                                                setError={this.setError}
                                                validation={m.validation}
                                                validation_error={language.PERSONAL[m.validation_error]}
                                            /> :
                                            m.type === 'text' ?
                                                <RegularInput
                                                    name={language.PERSONAL[m.name]}
                                                    no_numer={m.no_number}
                                                    not_required={m.not_required}
                                                    updateForm={this.updateFormLocal}
                                                    value={member[m.value] ? member[m.value] : ''}
                                                    state_name={m.state_name}
                                                    index={member_id}
                                                    extra_info={m.extra_info}
                                                    error={this.state[`${m.state_name}_err`]}
                                                    error_msg={language.PERSONAL[m.error_msg]}
                                                    setError={this.setError}
                                                    validation={m.validation}
                                                    validation_error={language.PERSONAL[m.validation_error]}
                                                /> :
                                                m.type === 'input_select' ?
                                                    <InputSelect
                                                        options={language.register[m.options] ? language.register[m.options] : []}
                                                        name={language.PERSONAL[m.name]}
                                                        updateForm={this.updateFormLocal}
                                                        value={member[m.value] ? member[m.value] : ''}
                                                        state_name={m.state_name}
                                                        index={member_id}
                                                        extra_info={m.extra_info}
                                                        filter_value={member[m.filter_value] ? member[m.filter_value] : ''}
                                                        filter_value_state_name={m.filter_value_state_name}
                                                        error={this.state[`${m.state_name}_err`]}
                                                        error_msg={language.PERSONAL[m.error_msg]}
                                                        setError={this.setError}
                                                    />
                                                    : ''
                        : ''
                    })}
                    {/* {member['member_experience'] == language.PERSONAL.VETEK_ARR[1] ?
                        <SelectBox
                            options={language.PERSONAL[EXTRA_SHIRT.options]}
                            value={member[EXTRA_SHIRT.value] ? member[EXTRA_SHIRT.value] : ''}
                            state_name={EXTRA_SHIRT.state_name}
                            updateForm={this.updateFormLocal}
                            placeholder={language.PERSONAL[EXTRA_SHIRT.placeholder]}
                            name={language.PERSONAL[EXTRA_SHIRT.name]}
                            index={member_id}
                            setError={this.setError}
                            error={this.state[`${EXTRA_SHIRT.state_name}_err`]}
                        /> : ''} */}
                    {/* {
                        member['member_experience'] == language.PERSONAL.VETEK_ARR[0] ||
                            member['another_shirt'] == language.PERSONAL.EXTRA_SHIRT_ARR[1]
                            ?
                            <ShirtSelect
                                no_shirt={true}
                                options={
                                    language.PERSONAL.VETEK_ARR[0] == this.state.brother_info.member_experience ?
                                        language.PERSONAL[SHIRT_SELECT.options_no_shirt] : language.PERSONAL[SHIRT_SELECT.options]}
                                value={member[SHIRT_SELECT.value] ? member[SHIRT_SELECT.value] : ''}
                                state_name={SHIRT_SELECT.state_name}
                                updateForm={this.updateFormLocal}
                                placeholder={language.PERSONAL[SHIRT_SELECT.placeholder]}
                                name={language.PERSONAL[SHIRT_SELECT.name]}
                                index={member_id}
                                error={this.state[`${SHIRT_SELECT.state_name}_err`]}
                            />

                            : ''

                    } */}
                </section>
                <section className='personal__extra__section__new'>
                    <header className='register__header'>{language.PERSONAL.EXTRA}</header>
                    {
                        personal_data.map((m, i) => {
                            return i > 26 ?
                                m.type === 'select' ?
                                    <SelectBox
                                        data={m}
                                        options={language.PERSONAL[m.options]}
                                        value={member[m.value] ? member[m.value] : ''}
                                        state_name={m.state_name}
                                        updateForm={this.updateFormLocal}
                                        placeholder={language.PERSONAL[m.placeholder]}
                                        name={language.PERSONAL[m.name]}
                                        index={member_id}
                                        error={this.state[`${m.state_name}_err`]}
                                        error_msg={language.PERSONAL[m.error_msg]}
                                        setError={this.setError}
                                    />
                                    :
                                    m.type === 'radio' ?
                                        <RadioInput
                                            state_name={m.state_name}
                                            name={language.PERSONAL[m.name]}
                                            updateForm={this.updateFormLocal}
                                            value={member[m.value]}
                                            options={language.PERSONAL[m.options]}
                                            check={m.check}
                                            index={member_id}
                                            error={this.state[`${m.state_name}_err`]}
                                            error_msg={language.PERSONAL[m.error_msg]}
                                            setError={this.setError}
                                        /> :
                                        m.type === 'select_sweet' && member[m.condition] == "כן" ?
                                        <SelectBox
                                        data={m}
                                        options={language.PERSONAL[m.options]}
                                        value={member[m.value] ? member[m.value] : ''}
                                        state_name={m.state_name}
                                        updateForm={this.updateFormLocal}
                                        placeholder={language.PERSONAL[m.placeholder]}
                                        name={language.PERSONAL[m.name]}
                                        index={member_id}
                                        error={this.state[`${m.state_name}_err`]}
                                        error_msg={language.PERSONAL[m.error_msg]}
                                        setError={this.setError}
                                    />
                                            : ''

                                : ''
                        })
                    }
                </section>


            </div> : ''
        )
    }
}



function mapStateToProps({ main }) {
    return { main }
}
export default connect(mapStateToProps, actions)(PersonalForm)