import React, { Component } from 'react'
import NumberFormat from 'react-number-format';
import validator from 'validator';
import moment from 'moment'

class NumberInput extends Component {
    constructor() {
        super()
        this.state = {
            focused: '',
            val: ''
        }
        this.sendValue = this.sendValue.bind(this);

    }


    sendValue(value) {
      
        let val_to_send = ''
        const { state_name, index, validation } = this.props
       
        if (value) {

            var numb = value.match(/\d/g)
            numb = numb.join("");

        }
        if (validation === 'day') {
            if (value < 1 ) {
                val_to_send = 1
                this.setState({
                    val: 1,
                    state_val:val_to_send
                })
            }
            if ( value > 31) {
                val_to_send = 31
                this.setState({
                    val: 31,
                    state_val:val_to_send
                })
            }
            else{
                val_to_send = numb ? numb : value
                this.setState({
                    val: numb ? numb : value
                })
            }
          
        }
       else if (validation === 'year') {
            let check_year = Number(moment().format('YYYY'))
            let check_year2 = Number(1900)
          
            if ( value > check_year) {
                val_to_send = check_year
                this.setState({
                    val: check_year,
                    state_val:val_to_send
                })
            }
             else if ( value < check_year2) {
                val_to_send = check_year2
                this.setState({
                    val: check_year2,
                    state_val:val_to_send
                })
            }
            
            else{
                val_to_send = numb ? numb : value
                this.setState({
                    val: numb ? numb : value
                })
            }
          
        }
        else{
            this.setState({
                val: numb ? numb : value
            })
        }

        // if (index >= 0) {
        //     this.props.updateForm(state_name, val_to_send, index)
        // } else {
        //     this.props.updateForm(state_name, val_to_send)
        // }
       
        this.props.updateForm(state_name, val_to_send)
    }

    componentDidMount() {
        const { value } = this.props
     
    
        this.setState({
            val: value,
            state_val:value
        })
    }



    handleOnBlur = async () => {
       setTimeout(async () => {
        const { val } = this.state
        const { state_name, index, validation,not_required } = this.props
         this.setState({ focused: false })
        let check = await this.validateInput(validation, val)
        if (!val && !not_required) {
            this.props.setError(state_name, true, index)
        }
        if (!check && !not_required) {
            this.props.setError(state_name, true, index, true)

        }
        if (index >= 0) {
            this.props.updateForm(state_name, val, index)
        } else {
            this.props.updateForm(state_name, val)
        }
       }, 100);
    }




    validateInput = (type, value) => {
      
        if (type === 'id') {
            if (value.length !== 9) {
                return false
            }
            else {
                return true
            }
        }
        if (type === 'phone') {

            if (value.length === 10) {
                return true
            } else {
                return false
            }
        }
        
        if (type === 'zip') {
            if (value.length < 5 || value.length > 7) {
                return false
            }
            else {
                return true
            }
        }


    }



    handleOnFocus = () => {
        const { value, state_name, index } = this.props
        this.setState({
            focused: true
        })
        this.props.setError(state_name, false, index)

    }


    render() {

        const { focused, val, state_val } = this.state
        const { placeholder, name, error_msg, error, validation_error, prefix } = this.props
        return (
            <div className={val || focused ? 'input__box__new common__input__class input__box__new__active' : 'input__box__new common__input__class'}>
                <h3>{name}</h3>
                {
                    name === 'מיקוד'
                    ?
                    <a 
                    href="https://mypost.israelpost.co.il/%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99%D7%9D/%D7%90%D7%99%D7%AA%D7%95%D7%A8-%D7%9E%D7%99%D7%A7%D7%95%D7%93/" 
                    target="_blank" 
                    className="zipcode__register__new">איתור מיקוד</a>
                    :
                    ''
                }
                
                <input
                type="number"
                
                    onChange={(e) => this.setState({state_val:e.target.value})}
                    onFocus={() => this.handleOnFocus()}
                    onBlur={(e) => {this.handleOnBlur(); this.sendValue(e.target.value)}}
                    placeholder={placeholder}
                    value={state_val}
            
                    className={focused ? 'reg__input reg__input__focused' : 'reg__input'} />
                    
                {error && !val ?
                    <p className='register__error'>{error_msg}</p>
                    : error && val ?
                        <p className='register__error'>{validation_error}</p> : ''
                }
            </div>
        )
    }
}

export default NumberInput