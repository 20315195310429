import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import './navbar.css';
import { a, withRouter } from 'react-router-dom';
import { languages } from '../../tools/keys';
import education from '../../images/navbar/education_bg.png';
import symbol from '../../images/navbar/symbol_bg.png';
import vision from '../../images/navbar/vision_big.png';
import activity from '../../images/navbar/activity_bg.png';
import LogoKrembo from '../../images/navbar/logo.svg';
import StripDesktop from '../../images/navbar/web_strip_igul_letova_desktop2.jpg';
import StripMobile from '../../images/navbar/web_strip_igul_letova_mobile.jpg';

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      noArabicPopup: false,
      drop_down: false,
      lang_selected: '',
      select_language: false,
      open_mobileMenu: false,
      aboutMobileMenu: false,
      pressMobileMenu: false,
      homeMobileMenu: false,
      active_navbar:
        (window.location.pathname.includes(
          'branches'
        ) ||
          window.location.pathname.includes(
            'vision'
          ) ||
          window.location.pathname.includes(
            'educationalconcept'
          ) ||
          window.location.pathname.includes(
            'activity'
          ) ||
          window.location.pathname.includes(
            'symbol'
          ) ||
          window.location.pathname.includes(
            'success'
          )) &&
        window.innerWidth < 1200,
    };
    this.handleScroll = this.handleScroll.bind(
      this
    );
  }

  componentDidMount() {
    const { selected_language } = this.props;

    this.setState({
      lang_selected: selected_language,
    });
    const url = window.location.pathname.split(
      '/'
    )[1];
    if (url) {
      let value;
      switch (url) {
        case 'en':
          value = 'EN';
          break;
        case 'ar':
          value = 'عربى';
          break;
        case 'he':
          value = 'עב';
          break;
        default:
          break;
      }
      this.setState({
        lang_selected: value,
      });
    }

    window.addEventListener(
      'scroll',
      this.handleScroll
    );
    window.addEventListener(
      'resize',
      this.resize
    );
  }
  componentWillReceiveProps(nextProps) {
    const check = nextProps.history.location.pathname.split(
      '/'
    )[2];

    if (!check) {
      this.setState({
        regular_navbar: true,
      });
    } else {
      this.setState({
        regular_navbar: false,
      });
    }
    // this.handleScroll()
  }

  resize = () => {
    this.forceUpdate();
    this.handleScroll();
  };

  componentWillUnmount() {
    window.removeEventListener(
      'scroll',
      this.handleScroll
    );
    window.removeEventListener(
      'resize',
      this.resize
    );
  }

  handleScroll() {
    if (
      (window.location.pathname.includes(
        'branches'
      ) ||
        window.location.pathname.includes(
          'vision'
        ) ||
        window.location.pathname.includes(
          'educationalconcept'
        ) ||
        window.location.pathname.includes(
          'activity'
        ) ||
        window.location.pathname.includes(
          'success'
        ) ||
        window.location.pathname.includes(
          'symbol'
        )) &&
      window.innerWidth < 1200
    ) {
      this.setState({
        active_navbar: false,
      });
      return;
    }
    const scrollTop = window.scrollY;
    if (window.innerWidth > 500) {
      if (scrollTop > 80) {
        this.setState({
          active_navbar: false,
        });
      } else {
        this.setState({
          active_navbar: false,
        });
      }
    } else if (window.innerWidth < 500) {
      if (scrollTop > 50) {
        this.setState({
          active_navbar: false,
        });
      } else {
        this.setState({
          active_navbar: false,
        });
      }
    } else {
      this.setState({
        active_navbar: false,
      });
    }
  }

  showDropDown() {
    this.setState({
      drop_down: 'show',
    });
  }
  hideDropDown() {
    this.setState({
      drop_down: 'hide',
    });
  }

  clickOutside(e) {
    const { select_language } = this.state;

    if (select_language) {
      this.setState({
        select_language: false,
      });
    }
  }

  changeLang(n) {
    if (n.lang === 'arabic') {
      this.setState({
        noArabicPopup: true,
      });
      return;
    }
    this.setState({
      lang_selected: n.text,
      select_language: false,
    });
    this.props.getLanguage(n.lang);
    let path = window.location.pathname.slice(3);
    if (n.lang === 'heb') {
      this.props.history.push('/he' + path);
    } else if (n.lang === 'eng') {
      this.props.history.push('/en' + path);
    } else if (n.lang === 'arabic') {
      this.props.history.push('/ar' + path);
    }
  }

  render() {
    const {
      drop_down,
      active_navbar,
      lang_selected,
      select_language,
      open_mobileMenu,
      regular_navbar,
    } = this.state;

    const {
      lang,
      active_navbar_white_page,
      selected_language,
      active_navbar_black_page,
    } = this.props;
    let urlLang =
      selected_language === 'עברית'
        ? 'he'
        : selected_language === 'English'
        ? 'en'
        : selected_language === 'عربى'
        ? 'ar'
        : null;

    return (
      <section
        className={
          active_navbar &&
          !active_navbar_black_page
            ? 'navbar__wrapper navbar__wrapper--active'
            : active_navbar_white_page
            ? 'navbar__wrapper navbar__wrapper--active2'
            : !active_navbar &&
              active_navbar_black_page
            ? 'navbar__wrapper navbar__wrapper--black'
            : 'navbar__wrapper'
        }
        onClick={(e) => this.clickOutside(e)}
      >
        <nav>
          <section className='nav__btns__new'>
            <span>
              <a
                to={'/' + urlLang}
                onClick={() => {
                  this.props.setSection(false);
                  this.props.resetNavbar(
                    false,
                    false,
                    true
                  );
                }}
              >
                {' '}
                <figure>
                  <span>
                    <h2>{lang.navbar_h2}</h2>
                    <h4>{lang.navbar_h4}</h4>
                  </span>{' '}
                </figure>
              </a>
              <aside>
                {' '}
                <a href='https://donation.krembo.org.il'>
                  <button
                    aria-label='go to donation'
                    className='donate__btn'
                  >
                    {selected_language === 'עברית'
                      ? 'תרומה'
                      : 'Donate'}
                  </button>
                </a>
                <a
                  href='https://www.krembo.org.il/%d7%94%d7%99%d7%a8%d7%a9%d7%9d/'
                  to={'/' + urlLang + '/signup'}
                  onClick={() => {
                    this.props.setSection(false);
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <button className='signup__btn'>
                    {lang.NAV_SIGN}
                  </button>
                </a>
                <h3>8849*</h3>
                {active_navbar ? (
                  <section className='language__select language__select__top'>
                    <svg
                      width='16px'
                      height='16px'
                      viewBox='0 0 16 16'
                      version='1.1'
                      xmlns='http://www.w3.org/2000/svg'
                      xlink='http://www.w3.org/1999/xlink'
                    >
                      <title>
                        baseline-language-24px@3x
                      </title>
                      <desc>
                        Created with Sketch.
                      </desc>
                      <g
                        id='web'
                        stroke='none'
                        strokeWidth='1'
                        fill='none'
                        fillRule='evenodd'
                      >
                        <g
                          id='baseline-language-24px'
                          transform='translate(-1.000000, -1.000000)'
                        >
                          <polygon
                            id='Path'
                            points='0 0 18.2066542 0 18.2066542 18 0 18'
                          ></polygon>
                          <path
                            d='M9.095741,1.5 C4.90821053,1.5 1.51722118,4.86 1.51722118,9 C1.51722118,13.14 4.90821053,16.5 9.095741,16.5 C13.2908576,16.5 16.689433,13.14 16.689433,9 C16.689433,4.86 13.2908576,1.5 9.095741,1.5 Z M14.3529124,6 L12.1150112,6 C11.8722558,5.0625 11.5232949,4.1625 11.0681285,3.33 C12.463972,3.8025 13.6246462,4.7625 14.3529124,6 Z M9.10332711,3.03 C9.7329739,3.93 10.2260708,4.9275 10.5522733,6 L7.65438088,6 C7.98058343,4.9275 8.47368032,3.93 9.10332711,3.03 Z M3.23168112,10.5 C3.11030343,10.02 3.03444237,9.5175 3.03444237,9 C3.03444237,8.4825 3.11030343,7.98 3.23168112,7.5 L5.79578493,7.5 C5.73509608,7.995 5.68957944,8.49 5.68957944,9 C5.68957944,9.51 5.73509608,10.005 5.79578493,10.5 L3.23168112,10.5 Z M3.85374181,12 L6.09164306,12 C6.33439845,12.9375 6.68335932,13.8375 7.13852568,14.67 C5.74268219,14.1975 4.58200798,13.245 3.85374181,12 Z M6.09164306,6 L3.85374181,6 C4.58200798,4.755 5.74268219,3.8025 7.13852568,3.33 C6.68335932,4.1625 6.33439845,5.0625 6.09164306,6 Z M9.10332711,14.97 C8.47368032,14.07 7.98058343,13.0725 7.65438088,12 L10.5522733,12 C10.2260708,13.0725 9.7329739,14.07 9.10332711,14.97 Z M10.8784759,10.5 L7.32817832,10.5 C7.25990337,10.005 7.20680063,9.51 7.20680063,9 C7.20680063,8.49 7.25990337,7.9875 7.32817832,7.5 L10.8784759,7.5 C10.9467508,7.9875 10.9998536,8.49 10.9998536,9 C10.9998536,9.51 10.9467508,10.005 10.8784759,10.5 Z M11.0681285,14.67 C11.5232949,13.8375 11.8722558,12.9375 12.1150112,12 L14.3529124,12 C13.6246462,13.2375 12.463972,14.1975 11.0681285,14.67 Z M12.4108693,10.5 C12.4715581,10.005 12.5170748,9.51 12.5170748,9 C12.5170748,8.49 12.4715581,7.995 12.4108693,7.5 L14.9749731,7.5 C15.0963508,7.98 15.1722118,8.4825 15.1722118,9 C15.1722118,9.5175 15.0963508,10.02 14.9749731,10.5 L12.4108693,10.5 Z'
                            id='Shape'
                            fill='#303030'
                            fillRule='nonzero'
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <figure
                      onClick={() =>
                        this.setState({
                          select_language: !select_language,
                        })
                      }
                    >
                      {lang_selected}
                    </figure>
                    <ul
                      className={
                        select_language
                          ? 'select__language--active lang__select'
                          : 'lang__select'
                      }
                    >
                      {languages
                        ? languages
                            .filter(function (
                              val
                            ) {
                              return (
                                val.text !==
                                lang_selected
                              );
                            })
                            .map((n, i) => (
                              <li
                                key={i}
                                onClick={() => {
                                  this.setState({
                                    lang_selected:
                                      n.text,
                                    select_language: false,
                                  });
                                  this.props.getLanguage(
                                    n.lang
                                  );
                                  this.changeLang(
                                    n
                                  );
                                }}
                              >
                                {n.text}
                              </li>
                            ))
                        : null}
                    </ul>
                  </section>
                ) : null}
              </aside>
            </span>
          </section>

          <div className='navbar__bottom'>
            <span>
              <ul>
                <a
                  href='https://krembo.org.il'
                  to={'/' + urlLang}
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li>{lang.NAVHOME}</li>
                </a>
                <a
                  href='https://www.krembo.org.il/%d7%a1%d7%a0%d7%99%d7%a4%d7%99%d7%9d/'
                  to={'/' + urlLang + '/branches'}
                  onClick={() => {
                    this.props.setSection(false);
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li>{lang.NAV2}</li>
                </a>
                <a
                  href='https://www.krembo.org.il/%d7%93%d7%95%d7%97%d7%95%d7%aa/'
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li
                    onClick={() => {
                      this.props.setSection(
                        'aboutValues'
                      );
                    }}
                  >
                    {selected_language === 'עברית'
                      ? 'דו״חות'
                      : 'Reports'}
                  </li>
                </a>
                <a
                  href='https://www.krembo.org.il/%d7%9e%d7%9f-%d7%94%d7%aa%d7%a7%d7%a9%d7%95%d7%a8%d7%aa/'
                  to={'/' + urlLang + '/press'}
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li
                    onClick={() => {
                      this.props.setSection('');
                    }}
                  >
                    {lang.NAV4}
                  </li>
                </a>

                <a
                  href='https://www.krembo.org.il/%D7%90%D7%95%D7%93%D7%95%D7%AA/'
                  to={'/' + urlLang + '/about'}
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li
                    onClick={() => {
                      this.props.setSection(
                        'aboutValues'
                      );
                    }}
                  >
                    {lang.NAV1}
                  </li>
                </a>

                <a
                  href='https://www.krembo.org.il/%d7%93%d7%a8%d7%95%d7%a9%d7%99%d7%9d/'
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li
                    onClick={() => {
                      this.props.setSection(
                        'aboutValues'
                      );
                    }}
                  >
                    {selected_language === 'עברית'
                      ? 'דרושים'
                      : 'Jobs'}
                  </li>
                </a>

                <a
                  href='https://www.krembo.org.il/%d7%94%d7%9b%d7%a9%d7%a8%d7%95%d7%aa/'
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li
                    onClick={() => {
                      this.props.setSection(
                        'aboutValues'
                      );
                    }}
                  >
                    {selected_language === 'עברית'
                      ? 'הכשרות'
                      : 'Training'}
                  </li>
                </a>
                <a
                  href='https://www.krembo.org.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/'
                  onClick={() => {
                    this.props.resetNavbar(
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <li
                    onClick={() => {
                      this.props.setSection(
                        'aboutValues'
                      );
                    }}
                  >
                    {selected_language === 'עברית'
                      ? 'צור קשר'
                      : 'Contact Us'}
                  </li>
                </a>
              </ul>
            </span>
          </div>
        </nav>

        <div
          style={
            this.props.yellow &&
            window.innerWidth > 500
              ? {}
              : {}
          }
          className={
            active_navbar &&
            !active_navbar_black_page
              ? 'mobile__nav mobile__nav--active'
              : active_navbar_black_page
              ? ' mobile__nav mobile__nav__black'
              : active_navbar &&
                active_navbar_black_page
              ? 'mobile__nav mobile__nav__black--active'
              : 'mobile__nav'
          }
        >
          {this.state.noArabicPopup ? (
            <figure className='arabic-lang-popup'>
              <div>
                <figure
                  onClick={() => {
                    this.setState({
                      noArabicPopup: false,
                    });
                  }}
                >
                  X
                </figure>

                <p>
                  גרסת אתר כנפיים של קרמבו בערבית
                  בבניה עמכם הסליחה
                </p>
                <img
                  src={LogoKrembo}
                  alt='krembo-wings'
                />
                <p>
                  المعذرة موقع " اجنحة كرمبو " قيد
                  الانشاء
                </p>
              </div>
            </figure>
          ) : null}

          <section>
            <span className='mobile__buttons'>
              <section>
                <a
                  href='https://krembo.org.il'
                  onClick={() => {
                    this.props.setSection(false);
                    this.props.resetNavbar(
                      false,
                      false,
                      false
                    );
                  }}
                >
                  <figure
                    className={
                      active_navbar_black_page
                        ? 'mobile__black__figure'
                        : null
                    }
                  >
                    {' '}
                  </figure>
                </a>
                <span>
                  <h2>{lang.navbar_h2}</h2>
                  <h4>{lang.navbar_h4}</h4>
                </span>
              </section>

              <section>
                <span className='mobile__buttons__container'>
                  <a href='https://donation.krembo.org.il'>
                    <button
                      aria-label='go to donation'
                      className='donate__btn'
                    >
                      {selected_language ===
                      'עברית'
                        ? 'תרומה'
                        : 'Donate'}
                    </button>
                  </a>
                  <a href='https://register.krembo.org.il'>
                    <button
                      className={
                        open_mobileMenu
                          ? 'signup__btn signup__btn--active'
                          : 'signup__btn'
                      }
                    >
                      {lang.NAV_SIGN}
                    </button>
                  </a>
                </span>

                <h3
                  className={
                    open_mobileMenu
                      ? 'mobile__h3--active'
                      : null
                  }
                >
                  8849*
                </h3>
              </section>
            </span>
            <section
              className={
                open_mobileMenu
                  ? 'mobile__hamburger mobile__hamburger--active'
                  : 'mobile__hamburger'
              }
              onClick={() =>
                this.setState({
                  open_mobileMenu: !open_mobileMenu,
                })
              }
            >
              <div></div>
              <div></div>
              <div></div>
            </section>
          </section>
          <div
            style={
              this.props.yellow &&
              window.innerWidth > 500
                ? { marginTop: '80px' }
                : { marginTop: '80px' }
            }
            className={
              open_mobileMenu
                ? 'mobile__nav__menu mobile__nav__menu--active'
                : 'mobile__nav__menu'
            }
          >
            <ul
              style={
                this.props.yellow &&
                window.innerWidth < 1220 &&
                window.innerWidth > 500
                  ? { paddingTop: '20px' }
                  : {}
              }
            >
              <a href='https://krembo.org.il'>
                <li className=''>
                  {lang.NAVHOME}
                </li>
              </a>
              <a href='https://www.krembo.org.il/%d7%a1%d7%a0%d7%99%d7%a4%d7%99%d7%9d/'>
                <li>{lang.NAV2}</li>
              </a>
              <a href='https://www.krembo.org.il/%d7%93%d7%95%d7%97%d7%95%d7%aa/'>
                <li>{lang.NAV3}</li>
              </a>
              <a href='https://www.krembo.org.il/%d7%9e%d7%9f-%d7%94%d7%aa%d7%a7%d7%a9%d7%95%d7%a8%d7%aa/'>
                <li className=''>מהתקשורת</li>
              </a>

              <a href='https://www.krembo.org.il/%D7%90%D7%95%D7%93%D7%95%D7%AA/'>
                <li className=''>{lang.NAV1}</li>
              </a>
              <a href='https://www.krembo.org.il/%d7%93%d7%a8%d7%95%d7%a9%d7%99%d7%9d/'>
                <li className=''>דרושים</li>
              </a>
              <a href='https://www.krembo.org.il/%d7%94%d7%9b%d7%a9%d7%a8%d7%95%d7%aa/'>
                <li className=''>הכשרות</li>
              </a>
              <a href='https://www.krembo.org.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/'>
                <li className=''>צור קשר</li>
              </a>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Navbar);
