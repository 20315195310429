

export const personal_data = [
    {
        type: 'prefix_text',
        prefix:'GENDER'

    },
    {
        type: 'select',
        name: 'GENDER',
        options: 'GENDER_ARR',
        placeholder: 'GENDER',
        state_name: 'gender',
        value: 'gender',
      
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'prefix_text',
        prefix:'BIRTH'

    },
    {
        type: 'select',
        name: 'DAY',
        placeholder: 'DAY',
        state_name: 'day',
        value: 'day',
        error_msg: 'ERROR_MSG',
        validation: 'day',
        options:'DAYS_ARR',
        required: true
    },
    {
        type: 'select',
        name: 'MONTH',
        options: 'MONTHS_ARR',
        placeholder: 'MONTH',
        state_name: 'month',
        value: 'month',
        error_msg: 'ERROR_MSG',
        required: true
    },

    {
        type: 'select',
        name: 'YEAR',
        placeholder: 'YEAR',
        state_name: 'year',
        value: 'year',
        error_msg: 'ERROR_MSG',
        validation: 'year',
        required: true,
        options: 'YEARS_ARR',
    },

    // info


    {
        type: 'text',
        name: 'NAME',
        no_number: true,
        placeholder: 'NAME',
        state_name: 'member_first_name',
        value: 'member_first_name',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'text',
        name: 'LAST_NAME',
        no_number: true,
        placeholder: 'LAST_NAME',
        state_name: 'member_last_name',
        value: 'member_last_name',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'number',
        name: 'ID',
        placeholder: 'ID',
        state_name: 'member_id_number',
        value: 'member_id_number',
        error_msg: 'ERROR_MSG',
        validation_error: 'VALIDATION_ERROR',
        validation: 'id',
        required: true
    },

    {
        type: 'text',
        name: 'ADDRESS',
        placeholder: 'ADDRESS',
        state_name: 'member_street',
        value: 'member_street',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'input_select',
        name: 'CITY',
        options: 'CITIES',
        placeholder: 'CITY',
        state_name: 'member_city',
        no_number: true,
        value: 'member_city',
        filter_value: 'member_city_filter_key',
        filter_value_state_name: 'member_city_filter_key',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'number',
        name: 'ZIP',
        placeholder: 'ZIP',
        state_name: 'zipcode',
        value: 'zipcode',
        error_msg: 'ERROR_MSG',
        validation: 'zip',
        validation_error: 'VALIDATION_ERROR_ZIP',
        required: false,
        not_required: true
    },
    {
        type: 'text',
        name: 'EMAIL',
        placeholder: 'EMAIL',
        state_name: 'member_email',
        value: 'member_email',
        error_msg: 'ERROR_MSG',
        validation: 'email',
        validation_error: 'VALIDATION_ERROR_EMAIL',
        required: true
    },
    {
        type: 'number',
        name: 'PHONE',
        placeholder: 'PHONE',
        state_name: 'member_phone',
        value: 'member_phone',
        error_msg: 'ERROR_MSG',
        validation: 'phone',
        validation_error: 'VALIDATION_ERROR_PHONE',
    },
    {
        type: 'select',
        name: 'EDUCATION_PROGRAM',
        options: 'EDUCATION_PROGRAM_ARR',
        placeholder: 'EDUCATION_PROGRAM',
        state_name: 'education_program',
        value: 'education_program',
      
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'text',
        name: 'SCHOOL',
        placeholder: 'SCHOOL',
        state_name: 'school',
        value: 'school',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type:'text',
        name:'HOME_ETA',
        placeholder:'HOME_ETA',
        state_name:'home_eta',
        value:'home_eta',
        error_msg:'ERROR_MSG',
        not_required: true
    },
    {
        type:'text',
        name:'PICKUP_LOCATION',
        placeholder:'PICKUP_LOCATION',
        state_name:'pickup_location',
        value:'pickup_location',
        error_msg:'ERROR_MSG',
        not_required: true
    },
    {
        type:'text',
        name:'CONTACT_NAME',
        placeholder:'CONTACT_NAME',
        state_name:'contact_person',
        value:'contact_person',
        error_msg:'ERROR_MSG',
        not_required: true
    },
    {
        type:'text',
        name:'CONTACT_PHONE',
        placeholder:'CONTACT_PHONE',
        state_name:'contact_phone',
        value:'contact_phone',
        error_msg:'ERROR_MSG',
        not_required: true
    },
    {
        type: 'select',
        name: 'GRADE',
        options: 'GRADE_ARR',
        placeholder: 'GRADE',
        state_name: 'grade_number',
        value: 'grade_number',
        error_msg: 'ERROR_MSG',
        required: true,
        no_required_if: ['education_program', 'חינוך מיוחד'],
        options:'GRADES_ARR'
    },
    // {
    //     type: 'placeholder',

    // },
    // {
    //     type: 'select',
    //     name: 'SHIHVA',
    //     options: 'SHIHVA_ARR',
    //     placeholder: 'SHIHVA',
    //     state_name: 'grade',
    //     value: 'grade',
    //     error_msg: 'ERROR_MSG',
    //     required: true
    // },
  
    {
        type: 'input_select',
        name: 'BRANCH',
        options: 'dropDown7',
        placeholder: 'BRANCH',
        state_name: 'branch',
        value: 'branch',
        error_msg: 'ERROR_MSG',
        filter_value: 'member_branch_filter_key',
        filter_value_state_name: 'member_branch_filter_key',
        required: true
    },




    {
        type: 'select',
        name: 'POSITION',
        options: 'POSITION_ARR',
        placeholder: 'POSITION',
        state_name: 'member_position',
        value: 'member_position',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'select_position',
        name: 'POSITION_RES',
        options: '',
        placeholder: 'POSITION_RES',
        state_name: 'member_position_additional',
        value: 'member_position_additional',
        condition: 'member_position',
        options: ['LEAD_TEAM_ARR', 'MANAGEMENT_TEAM_ARR'],
        placeholder_value: 'member_position_placeholder',
        error_msg: 'ERROR_MSG',
        required: false
    },
    {
        type: 'select',
        name: 'VETEK',
        options: 'VETEK_ARR',
        placeholder: 'VETEK',
        state_name: 'member_experience',
        value: 'member_experience',
        error_msg: 'ERROR_MSG',
    },


    {
        type: 'select',
        name: 'FOOD',
        options: 'FOOD_ARR',
        placeholder: 'FOOD',
        state_name: 'diet',
        value: 'diet',
        extra_info: `במידה ויש רגישויות למזון, יש לציין זאת בהמשך בחלק 'מידע רפואי'`,
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'select',
        name: 'HMO',
        options: 'HMO_ARR',
        placeholder: 'HMO',
        state_name: 'hmo',
        value: 'hmo',
        error_msg: 'ERROR_MSG',
        required: true
    },
 



    // {
    //     type:'select',
    //     name:SPECIAL_NEEDS,
    //     options:SPECIAL_NEEDS_ARR,
    //     placeholder:SPECIAL_NEEDS,
    //     state_name:'member_special_needs',
    //     value : 'member_special_needs',
    //     error_msg:'ERROR_MSG',


    // },

    {
        type: 'select',
        name: 'MOBILITY',
        options: 'MOBILITY_ARR',
        placeholder: 'MOBILITY',
        state_name: 'mobillity',
        value: 'mobillity',
        error_msg: 'ERROR_MSG',
        required: true
    },
    {
        type: 'radio',
        name: 'MOBILITY_REWARD',
        options: 'MOBILITY_REWARD_ARR',
        placeholder: 'MOBILITY_REWARD',
        state_name: 'mobility_reward',
        value: 'mobility_reward',
        check: true,
        error_msg: 'ERROR_MSG',
        required:true
    },
    {
        type: 'radio',
        name: 'DISABLITY_REWARD',
        options: 'DISABLITY_REWARD_ARR',
        placeholder: 'DISABLITY_REWARD',
        state_name: 'disabled_compensation',
        value: 'disabled_compensation',
        check: true,
        error_msg: 'ERROR_MSG',
        required:true
    },
    
    {
        type: 'select_sweet',
        name: 'PERCENT',
        placeholder: 'PERCENT',
        options: 'DISABLITY_PERCENT_ARR',
        state_name: 'disability_percent',
        value: 'disability_percent',
        condition: 'disabled_compensation',
        error_msg: 'ERROR_MSG'

    },
    {
        type: 'radio',
        name: 'CORONA_DETAILS',
        options: 'CORONA_DETAILS_ARR',
        placeholder: 'CORONA_DETAILS',
        state_name: 'corona_details',
        value: 'corona_details',
        check: true,
        error_msg: 'ERROR_MSG',
        required:true
    },


]

export const SHIRT_SELECT = {
    type: 'shirt_select',
    name: 'SHIRT_SIZE',
    options: 'SHIRT_ARR',
    options_no_shirt: 'SHIRT_ARR_NO_OPTION',
    placeholder: 'SHIRT_SIZE',
    state_name: 'shirt_size',
    value: 'shirt_size'
}




export const EXTRA_SHIRT = {
    type: 'select',
    options: 'EXTRA_SHIRT_ARR',
    name: 'EXTRA_SHIRT',
    placeholder: 'EXTRA_SHIRT',
    state_name: 'another_shirt',
    value: 'another_shirt'
}