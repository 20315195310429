import { GET_LANGUAGE, GET_LANGUAGE_ON_LOAD } from '../actions/types';
import { setCookie } from '../tools/cookies';

const initialState = {
  language: '',
  managers: '',
  vaad: '',
  selected_language: '',
  branches: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LANGUAGE:
      const { data, lang, selected_language } = action.payload;
      try {
        setCookie('language', lang);
        console.log(action);
      } catch (err) {}
      return {
        ...state,
        language: data,
        managers: data.managers,
        vaad: data.managers_vaad,
        selected_language,
      };

    case GET_LANGUAGE_ON_LOAD:
      return {
        ...state,
      };
    default:
      return state;
  }
}
