import React, { Component } from 'react'

class ExposureText extends Component {
    async componentDidMount(){
       
    }

async componentWillReceiveProps(nextProps){
    const {data, text} = this.props

    if(nextProps.parent_value !== this.props.parent_value && nextProps.parent_value === text ){
        if(data && data.length > 0) {
            let arr = []
          await  data.forEach(async element => {
              arr.push(element)
            });
            await   this.props.updateForm('exposure_aproval_options', arr)
        }
    }
    if(nextProps.parent_value !== this.props.parent_value && nextProps.parent_value !== text ){
        if(data && data.length > 0) {
            let arr = []
          await  data.forEach(async element => {
              arr.push(element)
            });
            await   this.props.updateForm('exposure_aproval_options', [])
        }
    }
}



    render () {
        const {data, data_extra} = this.props
       
        return (
            <ul className='exposure__list'>
            {
                data && data.length > 0 ?
                    data.map(m =>
                        <li>{m}</li>
                    )

                    : ''
            }
          
        </ul>
        )
    }
}

export default ExposureText