import React, { Component } from 'react'
import Personal from './forms/Personal'
// import RegisterNavbar from './navigation/RegisterNav'
import './register.css'
import './parts/parts.css'
import Selector from './brother-select/Selector'
import RegisterNavbar from './navigation/RegisterNav'
import Permissions from './forms/Permissions'
import Parents from './forms/Parents'
import Payment from './forms/Payment'
import RegisterImage from './parts/RegisterImage'
import RegisterNewTitleText from './parts/RegisterNewTitleText'
import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router'
import {withRouter} from 'react-router-dom'

class RegisterNew extends Component {
    constructor() {
        super()
        this.state = {
            brothers_arr:[
                {}
            ],
            index: 0,
            form: 'personal',
            brother_check: [],
            accessible_forms: [
                'personal', 
                'permissions', 
                'parents'
            ]
        }
    }

    componentDidMount() {
        // window.addEventListener('beforeunload', this.handleLeavePage);
        if(this.props.to_iframe) {
            const navbar = document.querySelector('section[class="navbar__wrapper"]')
            const footer = document.querySelector('section[class="footer__wrapper"]')
            navbar.remove()
            footer.remove()
        }
        let branch_discount = this.props.location.search
        if(branch_discount){
            branch_discount = branch_discount.split('=')[1] 
        }
       if(branch_discount === '5e554c78ee91ba27532086f0'){
        
       } else if(branch_discount === '5e554c78aa61ba45532068f1') {
        this.setState({ discount_last: true })
       } else if(branch_discount === 'movildiscount') {
           console.log("movil discount");
           this.setState({ movil_discount: true })
       } else if(branch_discount === 'parents_approval') {
           this.setState({ parents_approval_form: true })
       }
      }
      removeEventListener = () => {
        window.removeEventListener('beforeunload', this.handleLeavePage)
      }
    componentWillUnmount() {
        this.removeEventListener();
      }
      handleLeavePage(e) {
        const confirmationMessage = 'Some message';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
      }
    
    updateCoupon = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    updateForm = (name, value, brother_index) => {
        const { brothers_arr, index } = this.state
        let index_to_update

        if (brother_index >= 0) {
            index_to_update = brother_index
        } else {
            index_to_update = index
        }
        let arr = brothers_arr

        let brother = brothers_arr[index_to_update]
        brother[name] = value

        arr[index_to_update] = brother
        this.setState({
            brothers_arr: arr
        })
    }



   
    addBrother = () => {
        const { member_last_name, member_street, member_city, branch, zipcode, registration_price } = this.state.brothers_arr[0]
        this.setState({
            brothers_arr: [
                ...this.state.brothers_arr, 
                {
                    member_last_name, 
                    member_street,
                    member_city, 
                    branch, 
                    zipcode,
                    registration_price
                }
            ],
            accessible_forms: ['personal'],
            stored_accessible_forms: this.state.accessible_forms,
            stored_brothers_arr_length: this.state.brothers_arr.length
        })
    }
    updateIndex = (val) => {
        this.setState({
            index: val
        })
    }
    removeBrother = (index) => {
        const array = [...this.state.brothers_arr].filter((n, i) => index !== i)
        this.setState({ brothers_arr: array, index: 0 })
        if(this.state.stored_brothers_arr_length === array.length) {
            this.setState({ accessible_forms: this.state.stored_accessible_forms })
        }
    }
    setForm = (value, accessible_forms) => {
      
        this.setState({
            form: value !== this.state.form && accessible_forms ? this.state.form : value,
            accessible_forms: accessible_forms ? accessible_forms : [...this.state.accessible_forms, value]
        })
    }
    setBrotherProgress = (errors, index) => {
        const { brothers_arr } = this.state
        brothers_arr[index]['progress_errors'] = errors
       this.setState({
        brothers_arr
       })
    }
    sendPermissionErrors = (brothers_errors) => {
        this.setState({
            brothers_errors
        })
        let brother_check = brothers_errors.map(n => 0)
        brothers_errors.map((n, i) => {
            Object.keys(n).forEach(function (key) {

                if (n[key]) {
                    brother_check[i] = 1
                }
            })
        })
        this.setState({
            brother_check
        })
    }
    componentDidUpdate(nextProps, prevState) {
        if(this.state.form !== prevState.form) {
            window.scroll(0,0)
        }
    }
    updateAllBrothers = async (name, value) => {
       
        const { brothers_arr } = this.state
        let arr
        arr = await brothers_arr.map(val => {
            return {
                ...val,
                [name]: value
            }
        })
        this.setState({
            brothers_arr: arr
        })
     
    }

    updateBrotherByIndex = (brother, index) => {
        const { brothers_arr } = this.state
      
       if(brothers_arr[index]){
        const object3 = { ...brothers_arr[index], ...brother }
        brothers_arr[index] = object3
        this.setState({
            brothers_arr
        })
       }
    }

    render() {
        const { 
            brothers_arr, 
            index, 
            form, 
            brothers_errors, 
            brother_check, 
            coupon_code, 
            accessible_forms ,
            branch_discount,
            discount_last,
            movil_discount,
            parents_approval_form
        } = this.state
        const {language} = this.props

        return (
            // <Beforeunload onBeforeunload={() => "You'll lose your data!" } >
            <div className='register__container'
            style={
                form === 'payment' ? {} : {display: 'flex', flexDirection: 'row-reverse'}
            }
            >

                {
                    form === 'payment' ? '' : <RegisterImage form={form} />
                }
                <div className="new__register__wrapper__container">
                <RegisterNavbar
                    setForm={this.setForm}
                    selected={form}
                    accessible={accessible_forms}
                    parents_approval_form={parents_approval_form}
                />
                <RegisterNewTitleText stage={form} />
                 
                {
                    brothers_arr && brothers_arr.length > 0
                        && form === 'permissions' ?
                        <Selector
                            brothers={brothers_arr}
                            updateIndex={this.updateIndex}
                            brothers_errors={brothers_errors}
                            brother_check={brother_check}
                            index={index}
                        />
                        : ''
                }
                {form === 'personal' ? <Personal
                PERSONAL = {language.PERSONAL}
                    movil_discount={movil_discount}
                    updateForm={this.updateForm}
                    values={brothers_arr}
                    index={index}
                    addBrother={this.addBrother}
                    brothers={brothers_arr}
                    removeBrother={this.removeBrother}
                    setForm={this.setForm}
                    updateBrotherByIndex={this.updateBrotherByIndex}
                    branch_discount = {branch_discount}
                    discount_last = {discount_last}
                /> :

                    form === 'permissions' ?
                        <Permissions
                            currentForm={form}
                            updateForm={this.updateForm}
                            values={brothers_arr[index]}
                            index={index}
                            setBrotherProgress={this.setBrotherProgress}
                            sendPermissionErrors={this.sendPermissionErrors}
                            brothers={brothers_arr}
                            setForm={this.setForm}
                            language = {language}
                            discount_last = {discount_last}

                        />
                        :
                        form === 'parents' ?
                            <Parents
                            removeEventListener={this.removeEventListener}
                            parents_approval_form={parents_approval_form}
                            PERSONAL = {language.PERSONAL}
                            PARENTS = {language.PARENTS}
                            language = {language}
                            discount_last = {discount_last}
                                updateAllBrothers={this.updateAllBrothers}
                                updateForm={this.updateForm}
                                values={brothers_arr[index]}
                                index={index}
                                sendPermissionErrors={this.sendPermissionErrors}
                                brothers={brothers_arr}
                                setForm={this.setForm}
                            /> :
                            form === 'payment' ?
                                <Payment
                                    cancelReload={() => window.removeEventListener('beforeunload', this.handleLeavePage)}
                                    PAYMENT = {language.PAYMENT}
                                    coupon_code={coupon_code}
                                    updateCoupon={this.updateCoupon}
                                    values={brothers_arr[index]}
                                    index={index}
                                    sendPermissionErrors={this.sendPermissionErrors}
                                    brothers={brothers_arr}
                                    setForm={this.setForm}
                                    branch_discount = {branch_discount}
                                    discount_last = {discount_last}
                                    movil_discount={movil_discount}
                                />
                                : ''
                }
                </div>

            </div>
            // </Beforeunload>
        )
    }
}

export default withRouter(RegisterNew)