import { contentSecurityPolicy } from 'helmet';
import React, { Component, useRef, useEffect } from 'react'
// import chevron from '../../../../images/chevron.svg'
class SelectBox extends Component {
    constructor() {
        super()
        this.state = {
            selected: '',
            active: false
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }


    handleClickOutside(event) {
        const {active} = this.state
        const {value, state_name, index} = this.props
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && active) {
            this.setState({
                active: false
            })
            if(!value){
                    this.props.setError(state_name, true, index)
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    changeSelect = (val) => {
        const {index, state_name} = this.props
        this.props.setError(state_name, false, index)
        if(index >= 0){
            this.props.updateForm(state_name, val, index)
        }else{
            this.props.updateForm(state_name, val)
        }
      
        this.setState({
            selected: val,
            active: false
        });
    }
    render() {
        const { value, options, placeholder, prefix, placeholder_value, error, error_msg, no_required_if, member, extra_info } = this.props
        const notRequired = no_required_if ? member[no_required_if[0]] === no_required_if[1] : ''
        console.log(this.props, 'eses')
        const { active } = this.state
        const wrapperRef = useRef;
        return (
            <div
                ref={this.setWrapperRef}
                className={active  ?
                    'input__box__new select__box common__input__class select__box__active select__box__selected' :
                    value ?  'input__box__new select__box common__input__class select__box__selected'  :
                    'input__box__new select__box common__input__class'}>
              
                {prefix ? <h4>{prefix}</h4> : ''}
                
                <header onClick={() => this.setState({ active: !active })}>
                    <div className='select__arrow'> </div>
                   {value ?  <h3>{value}</h3> : ''}
                    <label className ={value ? 'select__box__selected' : ''}>{placeholder}</label>
                    {/* <img src={chevron} alt=""/> */}
                </header>
                <ul  >
                    {options && options.length > 0 ?
                        options.map((n, i) =>
                            <li key={i}
                                onClick={() => this.changeSelect(n)}
                            >{n}</li>)
                        : null}
                </ul>
                {
                    extra_info && !error ? <p className="extra_info_note">{extra_info}</p> : ''
                }
               {error && !notRequired ?  <p className='register__error'>{error_msg}</p> : ''}
            </div>
        )
    }
}

export default SelectBox