




export const permissions_data = [
    {
        type: 'radio_true_false',
        name: 'DISABILITY',
        options: 'DISABILITY_ARR',
        placeholder: 'DISABILITY',
        state_name: 'health_declaration',
        value: 'health_declaration',
        check: true,
        error_msg: 'ERROR_MSG',
        section:'health_declaration',
        required:true
    },
    {
        type: 'textarea',
        name: 'DISABILITY_PLACEHOLDER',
        placeholder: 'DISABILITY_PLACEHOLDER',
        state_name: 'health_declaration_description',
        value: 'health_declaration_description',
        error_msg: 'ERROR_MSG',
        section:'health_declaration',
      
    },
    {
        type: 'checkbox_true',
        name: 'ALERGY_H2',
        placeholder: 'ALERGY_PLACEHOLDER',
        state_name: 'has_alergy',
        value: 'has_alergy',
        error_msg: 'ERROR_MSG'
    },
    {
        type: 'textarea',
        name: 'ALERGY_PLACEHOLDER',
        placeholder: 'ALERGY_PLACEHOLDER',
        state_name: 'alergy_type',
        value: 'alergy_type',
        error_msg: 'ERROR_MSG',
        section:'alergy'
    },
    {
        type: 'textarea',
        name: 'ALERGY_PLACEHOLDER2',
        placeholder: 'ALERGY_PLACEHOLDER2',
        state_name: 'what_causes_allergy',
        value: 'what_causes_allergy',
        error_msg: 'ERROR_MSG',
        section:'alergy'
    },
    {
        type: 'textarea',
        name: 'ALERGY_PLACEHOLDER3',
        placeholder: 'ALERGY_PLACEHOLDER3',
        state_name: 'what_allergy_seizure_looks_like',
        value: 'what_allergy_seizure_looks_like',
        error_msg: 'ERROR_MSG',
        section:'alergy'
    },
    {
        type: 'textarea',
        name: 'ALERGY_PLACEHOLDER4',
        placeholder: 'ALERGY_PLACEHOLDER4',
        state_name: 'allergy_seizure_instructions',
        value: 'allergy_seizure_instructions',
        error_msg: 'ERROR_MSG',
        section:'alergy'
    },
    {
        type: 'upload',
        text:'',
        link:'',
        section:'alergy'
    },
    {
        type: 'checkbox_true',
        name: 'LETHAL',
        placeholder: 'LETHAL',
        not_required: true,
        state_name: 'leathal_alergy',
        value: 'leathal_alergy',
        error_msg: 'ERROR_MSG',
        section:'alergy'
    },

    {
        type: 'select',
        name: 'DIFICULTY_PLACEHOLDER',
        options:'DIFFICLTY_ARR_L',
        placeholder: 'DIFICULTY_PLACEHOLDER',
        state_name: 'difficulty_type',
        value: 'difficulty_type',
        error_msg: 'ERROR_MSG',
        section:'difficulty'
    },
    {
        type: 'textarea',
        name: 'DIFFICULTY_DESC',
        placeholder: 'DIFFICULTY_DESC',
        state_name: 'difficulty_desc',
        value: 'difficulty_desc',
        error_msg: 'ERROR_MSG',
        section:'difficulty'
    },
    {
        type:'text',
        name:'ATTENTION_REQUIRED_SITUATIONS',
        placeholder:'ATTENTION_REQUIRED_SITUATIONS',
        state_name:'attention_required_situations',
        value:'attention_required_situations',
        error_msg:'ERROR_MSG',
          section:'difficulty'
    },
    {
        type:'text',
        name:'CHALLENGING_BEHAVIOUR_RAGE',
        placeholder:'CHALLENGING_BEHAVIOUR_RAGE',
        state_name:'challenging_behavior',
        value:'challenging_behavior',
        error_msg:'ERROR_MSG',
          section:'difficulty'
    },
    {
        type:'text',
        name:'WHAT_CALMS_YOUR_CHILD',
        placeholder:'WHAT_CALMS_YOUR_CHILD',
        state_name:'what_calms_your_child',
        value:'what_calms_your_child',
        error_msg:'ERROR_MSG',
        not_required: true,
          section:'difficulty'
    },

    {
        type:'text',
        name:'CAN_RELIEVE_HIMSELF_DESCRIPTION',
        placeholder:'CAN_RELIEVE_HIMSELF_DESCRIPTION',
        state_name:'can_relieve_himself_description',
        value:'can_relieve_himself_description',
        error_msg:'ERROR_MSG',
          section:'can_relieve_himself'
    },
    {
        type: 'textarea',
        name: 'MEDICAL_EQUIUPMENT_PLACEHOLDER',
        placeholder: 'MEDICAL_EQUIUPMENT_PLACEHOLDER',
        state_name: 'medical_equipment_description',
        value: 'medical_equipment_description',
        error_msg: 'ERROR_MSG',
        section:'medical'
    },
    {
        type: 'checkbox_true',
        name: 'MEDICAL_SELF',
        placeholder: 'MEDICAL_SELF',
        state_name: 'self_assisted',
        value: 'self_assisted',
        error_msg: 'ERROR_MSG',
        section:'medical'
    },
    {
        type: 'textarea',
        name: 'DRUGS_PLACEHOLDR',
        placeholder: 'DRUGS_PLACEHOLDR',
        state_name: 'drugs_description',
        value: 'drugs_description',
        error_msg: 'ERROR_MSG',
        section:'medicine'
    },
    {
        type:'text',
        name:'EPILEPSY_1',
        placeholder:'EPILEPSY_1',
        state_name:'first_seizure_occurrence',
        value:'first_seizure_occurrence',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_2',
        placeholder:'EPILEPSY_2',
        state_name:'last_seizure_occurrence',
        value:'last_seizure_occurrence',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_3',
        placeholder:'EPILEPSY_3',
        state_name:'epilepsy_drugs_use',
        value:'epilepsy_drugs_use',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_4',
        placeholder:'EPILEPSY_4',
        state_name:'last_neurologist_visit',
        value:'last_neurologist_visit',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_5',
        placeholder:'EPILEPSY_5',
        state_name:'action_sequence_recommended',
        value:'action_sequence_recommended',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_6',
        placeholder:'EPILEPSY_6',
        state_name:'seizure_catalists',
        value:'seizure_catalists',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'select',
        name:'EPILEPSY_7',
        options:'EPILEPSY_7_ARR',
        placeholder:'EPILEPSY_7',
        state_name:'seizure_frequency',
        value : 'seizure_frequency',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_8',
        placeholder:'EPILEPSY_8',
        state_name:'how_does_seizure_lookLike',
        value:'how_does_seizure_lookLike',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_9',
        placeholder:'EPILEPSY_9',
        state_name:'pre_seizure_signs',
        value:'pre_seizure_signs',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_10',
        placeholder:'EPILEPSY_10',
        state_name:'emergency_epilepsy_medicine',
        value:'emergency_epilepsy_medicine',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_11',
        placeholder:'EPILEPSY_11',
        state_name:'way_of_taking_epilepsy_medicine',
        value:'way_of_taking_epilepsy_medicine',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
    {
        type:'text',
        name:'EPILEPSY_12',
        placeholder:'EPILEPSY_12',
        state_name:'time_to_take_epilepsy_medicine',
        value:'time_to_take_epilepsy_medicine',
        error_msg:'ERROR_MSG',
          section:'epilepsy'
    },
   
    {
        type: 'p_text',
        text:['EPILEPSY_LAST_P', 'EPILEPSY_LAST_P_2'],
   
        section:'epilepsy'
    },
    {
        type: 'checkbox_true',
        name: 'EPILEPSY_LAST_CHECK',
        placeholder: 'EPILEPSY_LAST_CHECK',
        state_name: 'seizure_terms_approval',
        value: 'seizure_terms_approval',
        error_msg: 'ERROR_MSG',
        section:'epilepsy'
    },
    {
        type: 'signature',
        name: 'EPILEPSY_LAST_CHECK',
        placeholder: 'EPILEPSY_LAST_CHECK',
        state_name: 'seizure_terms_signature',
        value: 'seizure_terms_signature',
        error_msg: 'ERROR_MSG',
        required: true,
        section:'epilepsy'
    },
    {
        type: 'checkbox_true',
        name: 'PARENTS_APPROVAL1',
        placeholder: 'PARENTS_APPROVAL1',
        state_name: 'parents_declartion_activity',
        value: 'parents_declartion_activity',
        error_msg: 'ERROR_MSG',
        section:'parents_approvals'
    },
    {
        type: 'p_text_bold',
        text_bold:'PARENTS_APPROVAL1_P_STRONG',
        text:'PARENTS_APPROVAL1_P',
        section:'parents_approvals'
    },
    {
        type: 'checkbox_true',
        name: 'PARENTS_APPROVAL2',
        placeholder: 'PARENTS_APPROVAL2',
        state_name: 'parents_declartion_terms',
        value: 'parents_declartion_terms',
        error_msg: 'ERROR_MSG',
        section:'parents_approvals'
    },
    // {
    //     type: 'checkbox_true',
    //     name: 'PARENTS_APPROVAL3',
    //     placeholder: 'PARENTS_APPROVAL3',
    //     state_name: 'parents_declartion_equip',
    //     value: 'parents_declartion_equip',
    //     error_msg: 'ERROR_MSG',
    //     not_required: true,
    //     dont_display: true,
    //     section:'parents_approvals'
    // },
    {
        type:'radio',
     
        options:'EXPOSURE_APPROVAL1_ARR',
        state_name:'exposure_aproval',
        value : 'exposure_aproval',
        check :true,
        error_msg:'ERROR_MSG',
        section:'exposure',
        selected:'EXPOSURE_APPROVAL1_ARR_SPECIAL'
    },
    {
        type: 'checkbox',
        name: 'PARENTS_APPROVAL3',
        options:'EXPOSURE_APPROVAL4_ARR',
        placeholder: 'PARENTS_APPROVAL3',
        state_name: 'exposure_aproval_options',
        value: 'exposure_aproval_options',
        error_msg: 'ERROR_MSG',
        section:'exposure_options'
    },
    {
        type: 'checkbox_true',
        name: 'EXPOSURE_APPROVAL5',
        placeholder: 'EXPOSURE_APPROVAL5',
        state_name: 'exposure_data_usage_agreement',
        value: 'exposure_data_usage_agreement',
        error_msg: 'ERROR_MSG',
        section:'exposure_options'
    },

    {
        type: 'p_text',
      
        text:'RIDE_P',
        section:'ride_permission'
    },
    {
        type:'radio',
        options:'RIDE_PERMISSION_OPTIONS',
        state_name:'way_of_arrival',
        value : 'way_of_arrival',
        check :true,
        error_msg:'ERROR_MSG',
        section:'ride_permission',
        optional:true
      
    },
    {
        type: 'checkbox_true',
        name: 'RIDE_PERMISSION1',
        placeholder: 'RIDE_PERMISSION1',
        state_name: 'ride_permission_term1',
        value: 'ride_permission_term1',
        error_msg: 'ERROR_MSG',
        section:'ride_permission',
        optional:true
    },
    {
        type: 'checkbox_true',
        name: 'RIDE_PERMISSION2',
        placeholder: 'RIDE_PERMISSION2',
        state_name: 'ride_permission_term2',
        value: 'ride_permission_term2',
        error_msg: 'ERROR_MSG',
        section:'ride_permission'
    },
    {
        type: 'checkbox_true',
        name: 'ALERGY_H2',
        placeholder: 'ALERGY_H2',
        state_name: 'has_alergy',
        value: 'has_alergy',
        error_msg: 'ERROR_MSG',
        section:'alergy',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'DIFICULTY',
        special_needs: true,
        placeholder: 'DIFICULTY',
        state_name: 'difficulty',
        value: 'difficulty',
        error_msg: 'ERROR_MSG',
        section:'difficulty',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'CAN_RELIEVE_HIMSELF',
        special_needs: true,
        placeholder: 'CAN_RELIEVE_HIMSELF',
        state_name: 'can_relieve_himself',
        value: 'can_relieve_himself',
        error_msg: 'ERROR_MSG',
        section:'can_relieve_himself',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'MEDICAL_EQUIUPMENT',
        placeholder: 'MEDICAL_EQUIUPMENT',
        state_name: 'medical_equipment',
        value: 'medical_equipment',
        error_msg: 'ERROR_MSG',
        section:'medical',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'DRUGS',
        placeholder: 'DRUGS',
        state_name: 'consume_medicine',
        value: 'consume_medicine',
        error_msg: 'ERROR_MSG',
        section:'medicine',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'EPILEPSY',
        placeholder: 'EPILEPSY',
        state_name: 'has_epilepsy',
        value: 'has_epilepsy',
        error_msg: 'ERROR_MSG',
        section:'epilepsy',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'SPECIAL_COMMUNICATION_WAYS',
        special_needs: true,
        placeholder: 'SPECIAL_COMMUNICATION_WAYS',
        state_name: 'special_communication_ways',
        value: 'special_communication_ways',
        error_msg: 'ERROR_MSG',
        section:'special_communication_ways',
        select_section:true
    },

    {
        type: 'checkbox_true',
        name: 'CANT_PREVENT',
        placeholder: 'CANT_PREVENT',
        state_name: 'cant_prevent',
        value: 'cant_prevent',
        error_msg: 'ERROR_MSG',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'MY_DUTY_TO_PROVIDE',
        placeholder: 'MY_DUTY_TO_PROVIDE',
        state_name: 'my_duty_to_provide',
        value: 'my_duty_to_provide',
        error_msg: 'ERROR_MSG',
        select_section:true
    },
    {
        type: 'checkbox_true',
        name: 'ON_CHANGE_MUST_ALERT',
        placeholder: 'ON_CHANGE_MUST_ALERT',
        state_name: 'on_change_must_alert',
        value: 'on_change_must_alert',
        error_msg: 'ERROR_MSG',
        select_section:true
    },
    {
        type: 'signature',
        name: 'health_signature',
        placeholder: 'health_signature',
        state_name: 'health_signature',
        value: 'health_signature',
        error_msg: 'ERROR_MSG',
        select_section:true
    },
    {
        type:'checkbox_true',
        name:'SPECIAL_COMMUNICATION_WAYS_1',
        placeholder:'SPECIAL_COMMUNICATION_WAYS_1',
        state_name:'special_communication_ways_talk',
        value:'special_communication_ways_talk',
        error_msg:'ERROR_MSG',
        section:'special_communication_ways',
        not_required: true
    },
    {
        type:'checkbox_true',
        name:'SPECIAL_COMMUNICATION_WAYS_2',
        placeholder:'SPECIAL_COMMUNICATION_WAYS_2',
        state_name:'special_communication_ways_jesta',
        value:'special_communication_ways_jesta',
        error_msg:'ERROR_MSG',
        section:'special_communication_ways',
        not_required: true
    },
    {
        type:'checkbox_true',
        name:'SPECIAL_COMMUNICATION_WAYS_3',
        placeholder:'SPECIAL_COMMUNICATION_WAYS_3',
        state_name:'special_communication_ways_board',
        value:'special_communication_ways_board',
        error_msg:'ERROR_MSG',
        section:'special_communication_ways',
        not_required: true
    },
    {
        type:'checkbox_true',
        name:'SPECIAL_COMMUNICATION_WAYS_4',
        placeholder:'SPECIAL_COMMUNICATION_WAYS_4',
        state_name:'special_communication_ways_ipad',
        value:'special_communication_ways_ipad',
        error_msg:'ERROR_MSG',
        section:'special_communication_ways',
        not_required: true
    },
    {
        type:'checkbox_true',
        name:'SPECIAL_COMMUNICATION_WAYS_5',
        placeholder:'SPECIAL_COMMUNICATION_WAYS_5',
        state_name:'special_communication_ways_computer',
        value:'special_communication_ways_computer',
        error_msg:'ERROR_MSG',
        section:'special_communication_ways',
        not_required: true
    },
    {
        type:'text',
        name:'SPECIAL_COMMUNICATION_WAYS_6',
        placeholder:'SPECIAL_COMMUNICATION_WAYS_6',
        state_name:'special_communication_ways_other',
        value:'special_communication_ways_other',
        error_msg:'ERROR_MSG',
        section:'special_communication_ways',
        not_required: true
    },
    
]


