import React from 'react'
import JOIN_CANCEL_POLICY from '../../../docs/new-signup/מכתב פתיחת שנה לפעילים ולהורים התשפ+ קוד לבוש 201920 סופי.pdf'
import CANCEL_POLICY from '../../../docs/new-signup/cancel.pdf'
export default (props) => <div className="register__new__title__text__first">
    <h1>ברוכים הבאים לתנועת “כנפיים של קרמבו”.</h1> 
    {
    props.stage === 'payment' ? 
    <p className="bold__register__statement"><strong>לאחר לחיצה על כפתור ׳מעבר לתשלום׳ תועברו לעמוד קרדיט גארד בו תתבקשו להזין את פרטי האשראי.<br/> אין לצאת מהאתר עד אחרי שהגעתם למסך ׳ההרשמה בוצעה בהצלחה׳ .</strong></p>
    : 
    <p>לנהלי השתתפות בפעילות התנועתית ללחוץ <a target="_blank" href='https://register.krembo.org.il/%D7%A0%D7%94%D7%9C%D7%99%20%D7%94%D7%A9%D7%AA%D7%AA%D7%A4%D7%95%D7%AA%20%D7%91%D7%A4%D7%A2%D7%99%D7%9C%D7%95%D7%AA%20%D7%94%D7%AA%D7%A0%D7%95%D7%A2%D7%AA%D7%99%D7%AA.pdf'>כאן</a>.</p>
    }
    {/* {props.stage === 'payment' ? '' :<p>להסבר לגבי ביטול ההרשמה יש ללחוץ <a target="_blank" href={CANCEL_POLICY}>כאן</a>.</p> } */}
</div>