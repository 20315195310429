import React, { Component } from 'react'

import finishedImg from '../images/finished.svg'
import not_FinishedImg from '../images/not_finished.svg'


class Selector extends Component {
    render() {
        const { brothers, brother_check, index } = this.props
        return (
            <ul className='brother__selector flex'>
                {
                    brothers.map((m, i) =>

                        <li
                            className={index == i &&  m.progress_errors  ===  0 ? 'register__nav__active register__nav__done register__nav__done__selected' :
                            index == i ? 'register__nav__active' :
                            m.progress_errors  ===  0 ? 'register__nav__done' :
                            ''}
                            onClick={() => this.props.updateIndex(i)}
                        >
                            <h3>{m.member_first_name}</h3>
                           
                                <img src={m.progress_errors === 0 ? finishedImg : not_FinishedImg} alt="" />
                           
                        </li>)

                }
            </ul>
        )
    }
}

export default Selector