import React, { Component } from 'react'

class ShirtSelect extends Component {
    render () {
        const {name, options, state_name, value, index} = this.props
        console.log(this.props)
        return (
            <div className='shirt__selector'>
                <h3>{name}</h3>
               <ul className='flex__wrap'>
               {
                    options && options.length > 0 ? 
                    options.map((m,i) => 
                       m === '' ? 
                       <li key = {i}
                        className={m == value ? 'active__shirt' : ''}
                        onClick = {() => this.props.updateForm(state_name, m, index)}
                        >
                       ללא חולצה
                        </li>
                       : <li key = {i}
                        className={m == value ? 'active__shirt' : ''}
                        onClick = {() => this.props.updateForm(state_name, m, index)}
                        >
                            {m}
                        </li>
                    )
                    :''
                }
               </ul>
            </div>
        )
    }
}

export default ShirtSelect