import React from 'react';
import { DIFF_SUM, DIFF_SUM_EN } from './Form';

export default (props) => {
  const { onClick, amount, radio_amount, text } = props;
  return (
    <div
      onClick={() => onClick(amount)}
      className={`${
        radio_amount === amount ? 'checked_radio_donation_amount' : ''
      } radio_box_donation_amount ${text ? 'with_text' : ''}`}
    >
      {/* <div className="Radio_button_amount">
        {radio_amount === amount ? <div /> : ''}
    </div> */}
      <p className={'sum-' + amount}> {amount}</p>
      <p>{amount === DIFF_SUM || amount == DIFF_SUM_EN ? '' : '₪ '}</p>
      {text && <p> - {text}</p>}
    </div>
  );
};
