import React, { Component } from 'react';

import ContactForm from './Parts/ContactForm';
import ContactManagersMap from './Parts/ContactManagersMap';

import bg from '../../images/contact/bg.png';

import './contact.css';

class Contact extends Component {
  componentDidMount() {
    if (this.props.to_iframe) {
      const navbar = document.querySelector(
        'section[class="navbar__wrapper"]'
      );
      const footer = document.querySelector(
        'section[class="footer__wrapper"]'
      );
      navbar.remove();
      footer.remove();
    }
    window.scrollTo(0, 0);
  }

  render() {
    const {
      lang,
      managers,
      language,
    } = this.props;
    return lang ? (
      <section className='contact__wrapper'>
        <img
          src={bg}
          alt=''
          className='contact__bg'
        />
        <div className='contact__flex'>
          <ContactForm lang={lang} />
          {managers ? (
            <ContactManagersMap
              managers={managers}
              lang={lang}
              language={language}
            />
          ) : null}
        </div>
      </section>
    ) : null;
  }
}

export default Contact;
