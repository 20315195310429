import countries from "./countries"



export const parents_data = [
    {
        type: 'text',
        name: 'NAME',
        no_number: true,
        placeholder: 'NAME',
        state_name: 'first_parent_name',
        value: 'first_parent_name',
        error_msg: 'ERROR_MSG',
        section: 'parent1',
        required:true
    },
    {
        type: 'number',
        name: 'PHONE',
        placeholder: 'PHONE',
        state_name: 'first_parent_phone',
        value: 'first_parent_phone',
        error_msg: 'ERROR_MSG',
        section: 'parent1',
        required:true,
        validation:'phone',
        validation_error:'VALIDATION_ERROR_PHONE',
    },
    {
        type: 'input_select',
        name: 'COUNTRY',
        options: 'COUNTRIES',
        placeholder: 'COUNTRY',
        state_name: 'first_parent_country',
        value: 'first_parent_country',
        error_msg: 'ERROR_MSG',
        section: 'parent1',
        filter_value:'first_parent_country_key',
        filter_value_state_name:'first_parent_country_key',
    },
    {
        type: 'number',
        name: 'YEAR',
        placeholder: 'YEAR',
        state_name: 'first_parent_arrival',
        value: 'first_parent_arrival',
        error_msg: 'ERROR_MSG',
        section: 'parent1',
        validation:'year'
    },
    {
        type: 'text',
        name: 'EMAIL',
        placeholder: 'EMAIL',
        state_name: 'first_parent_email',
        value: 'first_parent_email',
        error_msg: 'ERROR_MSG',
        section: 'parent1',
        required:true,
        validation:'email',
        validation_error:'VALIDATION_ERROR_EMAIL',
    },
    {
        type: 'text',
        name: 'WORK',
        placeholder: 'WORK',
        state_name: 'first_parent_work',
        value: 'first_parent_work',
        error_msg: 'ERROR_MSG',
        section: 'parent1',
    },
    {
        type: 'text',
        name: 'NAME',
        no_number: true,
        placeholder: 'NAME',
        state_name: 'second_parent_name',
        value: 'second_parent_name',
        // error_msg: ERROR_MSG,
        section: 'parent2',
       
    },
    {
        type: 'number',
        name: 'PHONE',
        placeholder: 'PHONE',
        state_name: 'second_parent_phone',
        value: 'second_parent_phone',
        // error_msg: ERROR_MSG,
        section: 'parent2',
        validation:'phone',
        validation_error:'VALIDATION_ERROR_PHONE',
     
    },
    {
        type: 'input_select',
        name: 'COUNTRY',
        options: 'COUNTRIES',
        placeholder: 'COUNTRY',
        state_name: 'second_parent_country',
        value: 'second_parent_country',
        // error_msg: ERROR_MSG,
        section: 'parent2',
        filter_value:'second_parent_country_key',
        filter_value_state_name:'second_parent_country_key',
       
    },
    {
        type: 'number',
        name: 'YEAR',
        placeholder: 'YEAR',
        state_name: 'second_parent_arrival',
        value: 'second_parent_arrival',
        // error_msg: ERROR_MSG,
        section: 'parent2',
        validation:'year'
      
    },
    {
        type: 'text',
        name: 'EMAIL',
        placeholder: 'EMAIL',
        state_name: 'second_parent_email',
        value: 'second_parent_email',
        error_msg: 'ERROR_MSG',
        section: 'parent2',
        validation:'email',
        validation_error:'VALIDATION_ERROR_EMAIL',
      
      
    },
    {
        type: 'text',
        name: 'WORK',
        placeholder: 'WORK',
        state_name: 'second_parent_work',
        value: 'second_parent_work',
        // error_msg: ERROR_MSG,
        section: 'parent2',
    
    },
    {
        type: 'text',
        name: 'SIGN_NAME',
        placeholder: 'SIGN_NAME',
        state_name: 'parent_approved_name',
        value: 'parent_approved_name',
        error_msg: 'ERROR_MSG',
        section: 'parent_approved',
        required:true
    },
    {
        type: 'number',
        name: 'ID',
        placeholder: 'ID',
        state_name: 'parent_approved_id',
        value: 'parent_approved_id',
        error_msg: 'ERROR_MSG',
        section: 'parent_approved',
        required:true,
        validation_error:'VALIDATION_ERROR',
        validation:'id'
    },
    {
        type: 'checkbox_true',
        name: 'TAKANON',
        placeholder: 'TAKANON',
        state_name: 'ride_permission_term3',
        value: 'ride_permission_term3',
        error_msg: 'ERROR_MSG',
        section: 'parent_approved',
        required:true
    },
    // {
    //     type: 'text_p',
    //     text: 'TAKANON_TEXT',
    //     link: 'TAKANON_LINK',
    //     section: 'parent_approved'

    // },
    // {
    //     type: 'checkbox_true',
    //     name: 'TAKANON',
    //      state_name: 'approved_terms',
    //     value: 'approved_terms',
    //     error_msg: 'ERROR_MSG',
    //     section: 'parent_approved',
    //     with_link:true,
    //     text: 'TAKANON2',
    //     text2: 'TAKANON2_1',
    //     text3: 'TAKANON2_2',
    //     link: 'TAKANON2_2_LINK',
    //     required:true
    // },
    {
        type: 'signature',
        text: 'WITH_MOUSE',
        text2: 'RESET',

        error_msg: 'ERROR_SIGN',
        section: 'parent_approved',
    },

]