import React, { Component } from 'react';
import axios from 'axios';

import From from './Parts/Form';

import { PROJECTS } from '../../tools/keys';

import './donate.css';

class Donate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle_select: false,
            project: props.lang.general,
        };
    }

    clickOutsideSelect(e) {
        const { toggle_select } = this.state;
        if (
            e.target.classList.value !== 'project__select__element' ||
            (e.target.classList.value !== 'roject__select' && toggle_select)
        ) {
            this.setState({
                toggle_select: false,
            });
        }
    }

    componentDidMount() {
        if (this.props.to_iframe) {
            const navbar = document.querySelector(
                'section[class="navbar__wrapper"]',
            );
            const footer = document.querySelector(
                'section[class="footer__wrapper"]',
            );
            navbar.remove();
            footer.remove();
        }
        window.scrollTo(0, 0);
        const url = window.location.pathname.split('/')[1];
        if (url === 'error') {
            this.setState({
                error_box: true,
            });
            const parameters = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );
            if (parameters && parameters.salesForceId) {
                const {
                    uniqueID,
                    personalId,
                    salesForceId,
                    txId,
                    mid,
                } = parameters;

                axios
                    .get(
                        `https://api.krembo.org.il/donationfailed/${uniqueID}?personalId=${personalId}&salesForceId=${salesForceId}&txId=${txId}&mid=${mid}`,
                    )
                    .then((res) => {
                        console.log('🚀 ~ getting  :', res.data);
                    });
            }
        }
    }

    toggleSelect = () => {
        const { toggle_select } = this.state;
        this.setState({
            toggle_select: !toggle_select,
        });
    };

    setProjectName = (project) => {
        this.setState({
            project: project,
        });
    };
    render() {
        const { lang, selected_language } = this.props;

        const { toggle_select, project, error_box } = this.state;
        return (
            <section
                className="donate__wrapper"
                onClick={(e) => this.clickOutsideSelect(e)}
            >
                {/* <figure className='donate__bg'>

            </figure> */}
                <div className="donation__flex">
                    {/* <div className='donate__header donation_iframe_agent'><h1>{lang.header.split(',').map(n=> <div>{n}</div>)}</h1>​</div> */}
                    {/* <div class="motnhly_donation_div">
                <a 
                href="https://ws.payplus.co.il/pp/cc/oc.aspx?ppl=1D37B0021F0B49E68" target="_blank" ref="nofollow"> 
                    {lang.monthly_donation}
                </a>
                </div> */}

                    {PROJECTS ? (
                        <From
                            lang={lang}
                            PROJECTS={PROJECTS}
                            toggle_select={toggle_select}
                            project={project}
                            toggleSelect={this.toggleSelect}
                            setProjectName={this.setProjectName}
                            error_box={error_box}
                            closeError={this.closeError}
                            selected_language={selected_language}
                        />
                    ) : null}
                </div>
            </section>
        );
    }
}

export default Donate;
