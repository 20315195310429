import React, { Component } from 'react'
import RadioInput from '../../parts/RadioInput'
import CheckBoxTrueFalse from '../../parts/CheckBoxTrueFalse'
import RegularCheckbox from '../../parts/RegularCheckbox'
import ExposureText from '../../parts/ExposureText'

class PermissionExposure extends Component {
    render() {

        const { data, values, errors, PERMISSIONS } = this.props
        return (
            <div className='permission__exposure'>
                {
                    data.filter(n => n.section === 'exposure').map((m, i) =>
                        m.type === 'radio' ?
                            <RadioInput
                                key={i}
                                name={PERMISSIONS[m.name]}
                                options={PERMISSIONS[m.options]}
                                placeholder={m.placeholder}
                                state_name={m.state_name}
                                value={values[m.value] ? values[m.value] : ''}
                                error_msg={PERMISSIONS[m.error_msg]}
                                selected={PERMISSIONS[m.selected]}
                                error={errors[`${m.value}_err`]}
                                updateForm={this.props.updateForm}
                                setError={this.props.setError}
                                updateStateOnLoad={this.props.updateStateOnLoad}
                                permission={true}
                                render_onload={true}
                                data_to_render={
                                    <span>
                                        <ExposureText
                                            data={PERMISSIONS['EXPOSURE_APPROVAL4_ARR']}
                                            updateForm={this.props.updateForm}
                                            // data_extra={PERMISSIONS['EXPOSURE_APPROVAL5']}
                                            parent_value={values['exposure_aproval']}
                                            text={PERMISSIONS['EXPOSURE_APPROVAL1_ARR_SPECIAL']}
                                        />
                                      
                                  
                                            <CheckBoxTrueFalse
                                                name={PERMISSIONS['EXPOSURE_APPROVAL5']}
                                                placeholder={PERMISSIONS['EXPOSURE_APPROVAL5']}
                                                state_name={'exposure_data_usage_agreement'}
                                                value={values['exposure_data_usage_agreement'] ? values['exposure_data_usage_agreement'] : ''}
                                                error_msg={PERMISSIONS['error_msg']}
                                                updateForm={this.props.updateForm}
                                                error={errors[`exposure_data_usage_agreement_err`]}
                                                setError={this.props.setError}
                                                updateStateOnLoad={this.props.updateStateOnLoad}
                                                permission={true}
                                            />
                                           
                                        
                                    </span>
                                }
                            /> : ''

                    )}
            </div>
        )
    }
}

export default PermissionExposure