import {
    GET_LANGUAGE,
    GET_LANGUAGE_ON_LOAD
} from './types'
import axios from 'axios'
import { API } from '../tools/keys'




export const getLanguage = (language) => async dispatch => {
    let data
    let selected_language
    let lang
    if (language === 'heb') {
        lang = 'hebrew'
        selected_language = 'עברית'
    }
    if (language === 'eng') {
        lang = 'english'
        selected_language = 'English'
    }
    if (language === 'arabic') {
        lang = 'arabic'
        selected_language = 'عربى'
    }

    axios.get(`${API}/content`).then(res => {
        if(res.data.result){
            data = res.data.result[lang]
          
            dispatch({
                type: GET_LANGUAGE,
                payload: {data, lang, selected_language}
            })
        }
       
    })



}



export const getLanguageOnLoad = (language) => async dispatch => {
    let data
    let lang
    if (language === 'heb') {
        lang = 'hebrew'
    }
    if (language === 'eng') {
        lang = 'english'
    }
    if (language === 'arabic') {
        lang = 'arabic'
    }

    axios.get(`${API}/content`).then(res => {
        if(res.data.result){
            data = res.data.result[lang]
            dispatch({
                type: GET_LANGUAGE_ON_LOAD,
                payload: {data, lang}
            })
        }
       
    })



}