

export const all_branches = {
    'אופקים':	250,
    'אשדוד':	400,
    'אשקלון':	300,
    'באר שבע':	400,
    'בית שאן':	250,
    'בת ים':	250,
    'גבעתיים':	500,
    'גבעת שמואל': 330,
    'הוד השרון':	500,
    'הרצליה':	500,
    'חדרה':	400,
    'חולון מזרח':	400,
    'חיפה':	500,
    'טבריה':	250,
    'טירה':	0,
    'יבנה':	400,
    'יהוד':	500,
    'יקנעם':	400,
    'ירושלים דרום':	250,
    'כוכב יאיר- צור יגאל':	500,
    'כפר סבא':	500,
    'כרמיאל':	400,
    'לוד':	250,
    'מודיעין ':	500,
    'נס ציונה':	500,
    'נוף הגליל':	400,
    'נתניה':	400,
    'ערערה ':	0,
     "פתח תקווה אם המושבות": 400,
    'צפת':	250,
    'קרית שמונה':	400,
    'קרית אונו':	500,
    'קרית גת':	250,
    'ראש העין':	400,
    'רהט':	0,
    "לקיה":0,
    'רמת גן דרום': 350,
    'רמת גן צפון': 350,
    'רעננה':	500,
    'שדרות':	125,
    'תל אביב דרום':	250,
    'תל אביב צפון':	500,
   "מג'ד אלכרום":	100,
    'עכו':	150,
    'פרדס חנה':	400,
    'לב השרון':	500,
    'רחובות':	400,
    'רמלה':	250,
    'יפו':	250,
    'ראשון לציון מזרח ':	400,
    'דימונה':	200,
    'ירכא':	100,
    'חורה':	0,
    'רמת השרון':	500,
    'מעלה אדומים':	400,
    'חולון מערב ':	400,
    'קרית מלאכי ':	250,
    'אילת':	400,
    'דרום השרון':	500,
    'ראשון לציון מערב ':	500,
    'בני שמעון':	400,
    'אריאל':	400,
    'מטה אשר':	400,
    'תל שבע':	0,
    'קרית חיים ':	500,
    'שפרעם ':	100,
    'אור יהודה':	400,
    'עמק חפר':	500,
    'נתיבות':	250,
    'ירוחם':	250,
    'תל אביב מרכז':	500,
    'מועצת גזר':	500,
    'ירושלים מערב':	250,
    'עפולה':	250,
    "פתח תקווה סירקין": 400,
    'טייבה':	0,
    "ג'וליס":	100,
    'שוהם':	500,
    'תל אביב – רמת אביב': 500,
    'נהריה':	400,
    'יפיע':	125,
    'עראבה':	125,
    "ינוח ג׳ת": 100,
    "עומר": 500,
    "תל אביב - לב תל אביב": 500,
    "רמת גן - רמת חן": 350,
    "מע'אר": 100,
    "באקה אלע'רבייה": 0,
}
