import React, { Component } from 'react'
import Radio from '@material-ui/core/Radio';
import TextAreaInput from './TextAreaInput';

class RadioTrueFalse extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
        const {
            state_name,
            value
        } = this.props
        if (!value) this.props.updateForm(state_name, false)
    }


    render() {

        const {
            state_name,
            name,
            value,
            options,
            index,
            error,
            error_msg
        } = this.props
        console.log(this.props, '1231k1k1')
        return (
            <div className='radio__container'>
                <h3>{name}</h3>
                <ul>
                    {options && options.length > 0 ?
                        options.map((n, i) =>
                        <>
                            <li key={i} >
                                <span>
                                    <Radio
                                        checked={value === n.value}
                                        onChange={() =>
                                            index >= 0 ?
                                                this.props.updateForm(state_name, n.value, index)
                                                :
                                                this.props.updateForm(state_name, n.value)
                                        }
                                    />
                                </span>
                                <h3> {n.text}</h3>
                            </li>
                            {n.note ? <p className="note-of-health-allergies">{n.note}</p>  :''} 

                            </>
                            )
                        
                        : null}
                </ul>
                {error ? <p className='register__error'>{error_msg}</p> : ''}
            </div>
        )
    }
}

export default RadioTrueFalse