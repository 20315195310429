import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox';

class RegularCheckbox extends Component {
    constructor() {
        super()
        this.state = {
            checked: []
        }

    }

    componentDidMount = () => {
    
        const { value, permission, state_name, all_checked, options } = this.props
      
        if (value) this.setState({ checked: value })
        if(permission){
            this.props.updateStateOnLoad(state_name, true)
        }
        if(all_checked){
          
            options.forEach(val => {
                console.log(val)
                this.props.updateForm(state_name, val)
            })
           
        }
    }
    handleChange = (val) => {
        const { checked,  } = this.state
        const {state_name} = this.props
        let arr
        if (checked.includes(val)) {
            arr = checked.filter(m => m !== val)
        }
        else {
            arr = [...checked, val]
        }
        this.setState({
            checked: arr
        })
        if(arr.length > 0){
            this.props.setError(state_name, false)
        }else{
            this.props.setError(state_name, true)
        }

        this.props.updateForm(state_name, arr)

    }



    sendData = () => {
        const { state_name } = this.props
        const { checked } = this.state
        this.props.updateForm(state_name, checked)
    }


    componentWillUnmount() {
        const { state_name, permission } = this.props
        this.props.setError(state_name, false)
        if(permission){
            this.props.updateStateOnLoad(state_name, false)
        }
    }


    render() {

        const { name, value, data_to_render, options, error, error_msg, state_name } = this.props
      
        const { checked } = this.state
        return (
            <div className='register__checkbox'>
                {
                    options.map((m, i) =>
                        <aside className='checkbox__flex'>

                            <span>
                                <figure>
                                    <Checkbox
                                       
                                        checked={checked.includes(m)}
                                        onChange={() => this.handleChange(m)}
                                    />
                                </figure>
                            </span>
                            <h3>{m}</h3>
                        </aside>
                    )
                }
                {error ? <p className='register__error'>{error_msg}</p> : ''}
            </div>
        )
    }
}

export default RegularCheckbox