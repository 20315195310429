import React, { Component } from 'react'
import NumberFormat from 'react-number-format';

class PercentInput extends Component {
    constructor() {
        super()
        this.state = {
            focused: ''
        }
        this.checkIfPercent = this.checkIfPercent.bind(this);
    }
     

    checkIfPercent(name, value){
        let elem = Number(value)
        const {index} = this.props
     
            elem =  value.replace('%','');

        this.props.updateForm(name, Number(elem), index)
     
    }
    render() {
        const { focused } = this.state
        const { placeholder, value, name, state_name } = this.props
      
        return (
            <div className={focused || value ? 'percent__box input__box__new common__input__class input__box__new__active' :
             'percent__box input__box__new common__input__class '}>
                <h3>{name}</h3>
                <NumberFormat 
               
                onChange={(e) => this.checkIfPercent(state_name, e.target.value)}
                onFocus={() => this.setState({
                    focused: true
                })}
                onBlur={() => this.setState({
                    focused: false
                })}
                decimalScale = {2}
             
                isAllowed={(values) => {
                    const {floatValue} = values;
                   if(floatValue > -1 && floatValue <= 188) return floatValue;
                    else if( values.value == '')  { return  0}
                  }}
                placeholder={placeholder}
                value={value}
                className={focused ? 'reg__input reg__input__focused' : 'reg__input'} 
                suffix={'%'} />
              
              
            </div>
        )
    }
}

export default PercentInput