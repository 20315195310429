import React, { Component } from 'react'
import CheckBoxTrueFalse from '../../parts/CheckBoxTrueFalse'
import RadioTrueFalse from '../../parts/RadioTrueFalse'
import TextAreaInput from '../../parts/TextAreaInput'
import RegularInput from '../../parts/RegularInput'
import SelectBox from '../../parts/SelectBox'
import UploadDoctorTestimony from '../../parts/UploadDoctorTestimony'



import DoctorTestimonyDoc from '../../docs/טופס_אישור_רופא_פעילות_שנתית_כנפיים.pdf'
import Signature from '../../parts/Signature'

class PermissionsSpecialNeeds extends Component {
    constructor() {
        super()
        this.state = {

        }
    }


    setError = () => {

    }
    render() {

        const { parents, types, values, data, checked_value, errors, language } = this.props
        const {PERMISSIONS} = language
        console.log(data)
        let index
        if (data && data.length > 0) index = data.findIndex(m => m.section === 'health_declaration')

        return (
            data && data.length > 0 ? <div>
                {
                    true ?
                        <span>
                            {data.filter(n => n.special_needs).filter(n => n.section === 'health_declaration').map((m, i) =>
                                m.type === 'textarea' ?
                                    <TextAreaInput
                                        name={PERMISSIONS[m.name]}
                                        placeholder={PERMISSIONS[m.placeholder]}
                                        state_name={m.state_name}
                                        value={values[m.value] ? values[m.value] : ''}
                                        error_msg={PERMISSIONS[m.error_msg]}
                                        updateForm={this.props.updateForm}
                                        setError={this.props.setError}
                                        error={errors[`${m.value}_err`]}
                                        updateStateOnLoad={this.props.updateStateOnLoad}
                                        permission={true}
                                    /> : '')}
                            {
                                data.filter(n => n.special_needs).filter(m => m.select_section === true).map((type, index) => (
                                    type.type === 'checkbox_true' ?
                                    <span>
                                        <CheckBoxTrueFalse
                                            key={index}
                                            name={PERMISSIONS[type.name]}
                                            not_required={type.not_required}
                                            section={type.section}
                                            placeholder={PERMISSIONS[type.placeholder]}
                                            state_name={type.state_name}
                                            value={values[type.value] ? values[type.value] : ''}
                                            error_msg={PERMISSIONS[type.error_msg]}
                                            updateForm={this.props.updateForm}
                                            setError={this.setError}
                                            error={errors[`${type.value}_err`]}

                                            data_to_render={

                                                <span className='register__textbox__content'>
                                                    {
                                                        data.filter(n => n.section === type.section).map((m, i) =>
                                                            m.type === 'upload' ?
                                                                <span className='upload__box'>
                                                                    <p>{PERMISSIONS.UPLOAD_TEXT} <a href={DoctorTestimonyDoc}
                                                                     download>{PERMISSIONS.UPLOAD_DOWNLOAD}</a>
                                                                     </p>
                                                                    <UploadDoctorTestimony 
                                                                    member_id_number={values['member_id_number'] ?
                                                                     values['member_id_number'] : ''} />
                                                                </span>
                                                                :
                                                                m.type === 'textarea' ?
                                                                    <TextAreaInput
                                                                        name={PERMISSIONS[m.name]}
                                                                        placeholder={PERMISSIONS[m.placeholder]}
                                                                        state_name={m.state_name}
                                                                        value={values[m.value] ? values[m.value] : ''}
                                                                        error_msg={PERMISSIONS[m.error_msg]}
                                                                        updateForm={this.props.updateForm}
                                                                        setError={this.props.setError}
                                                                        error={errors[`${m.value}_err`]}
                                                                        updateStateOnLoad={this.props.updateStateOnLoad}
                                                                        permission={true}
                                                                    /> :
                                                                    m.type === 'checkbox_true' && !m.select_section ?
                                                                        <CheckBoxTrueFalse
                                                                            name={PERMISSIONS[m.name]}
                                                                            not_required={m.not_required}
                                                                            placeholder={PERMISSIONS[m.placeholder]}
                                                                            state_name={m.state_name}
                                                                            value={values[m.value] ? values[m.value] : ''}
                                                                            error_msg={PERMISSIONS[m.error_msg]}
                                                                            updateForm={this.props.updateForm}
                                                                            setError={this.props.setError}
                                                                            updateStateOnLoad={this.props.updateStateOnLoad}
                                                                            permission={true}
                                                                            error={errors[`${m.value}_err`]}
                                                                            extra_class={m.section === 'epilepsy' ? 'epilepsy' : ''}
                                                                        /> :
                                                                        m.type === 'text' ?
                                                                            <RegularInput
                                                                                name={PERMISSIONS[m.name]}
                                                                                placeholder={PERMISSIONS[m.placeholder]}
                                                                                state_name={m.state_name}
                                                                                value={values[m.value] ? values[m.value] : ''}
                                                                                error_msg={PERMISSIONS[m.error_msg]}
                                                                                updateForm={this.props.updateForm}
                                                                                setError={this.props.setError}
                                                                                error={errors[`${m.value}_err`]}
                                                                                updateStateOnLoad={this.props.updateStateOnLoad}
                                                                                permission={true}
                                                                            />
                                                                            :
                                                                            m.type === 'select' ?
                                                                                <SelectBox
                                                                                    options={PERMISSIONS[m.options] ? PERMISSIONS[m.options] : []}
                                                                                    value={values[m.value] ? values[m.value] : ''}
                                                                                    state_name={m.state_name}
                                                                                    updateForm={this.props.updateForm}
                                                                                    placeholder={PERMISSIONS[m.placeholder]}
                                                                                    name={PERMISSIONS[m.name]}
                                                                                    updateStateOnLoad={this.props.updateStateOnLoad}
                                                                                    permission={true}
                                                                                    setError={this.props.setError}
                                                                                    error_msg={PERMISSIONS[m.error_msg]}
                                                                                    error={errors[`${m.value}_err`]}
                                                                                    placeholder_value={values[m.placeholder_value] ? values[m.placeholder_value] : ''}
                                                                                /> :
                                                                                m.type === 'p_text' ?
                                                                                    m.text.map((m, i) =>
                                                                                        <p>{PERMISSIONS[m]}</p>
                                                                                    )
                                                                                    : 
                                                                                    
                                                                                m.type === 'signature' ?
                                                                                <Signature 
                                                                                    {...m}
                                                                                    setError={this.props.setError}
                                                                                    error_msg={PERMISSIONS[m.error_msg]}
                                                                                    permission={true}
                                                                                    error={errors[`${m.value}_err`]}
                                                                                    updateStateOnLoad={this.props.updateStateOnLoad}
                                                                                    updateForm={this.props.updateForm}
                                                                                />
                                                                                :''

                                                        )}





                                                </span>
                                            }
                                        />

                                    </span>
                                    :
                                    <Signature 
                                    {...type}
                                    setError={this.props.setError}
                                    error_msg={PERMISSIONS[type.error_msg]}
                                    permission={true}
                                    error={errors[`${type.value}_err`]}
                                    updateStateOnLoad={this.props.updateStateOnLoad}
                                    updateForm={this.props.updateForm}
                                />
                                ))
                            }

                        </span>
                        : ''
                }
            </div> : ''
        )
    }
}

export default PermissionsSpecialNeeds