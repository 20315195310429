import React, { Component } from "react"
import NumberInput from "../parts/NumberInput"
import { parents_data } from "../data/parents"
import RegularInput from "../parts/RegularInput"
import InputSelect from "../parts/InputSelect"
import CheckBoxTrueFalse from "../parts/CheckBoxTrueFalse"
import { fabric } from "fabric"
import axios from "axios"
import LinkImg from "../../../images/register/link.svg"
import { API } from "../../../tools/keys"
import { dataURItoBlob } from "../../../tools/data_url"
import Loader from "../../Loader/Loader"

class Parents extends Component {
  constructor() {
    super()
    this.state = {
      parents_info: {},
      parents_errors: {},
    }
  }

  resize = (e) => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    })
  }

  async componentWillMount() {
    const { brothers, language } = this.props
    const { PERMISSIONS } = language
    await this.resize()
    const { canvas } = this.state
    const { values } = this.props
    if (!canvas) {
      const canvas = new fabric.Canvas("fabric__canvas__signature")
      this.setState({ canvas })
      if (values["canvas_lines"]) {
        values["canvas_lines"].map((n) => {
          canvas.add(n)
        })
      }
      console.log(canvas,'hello')
      canvas.freeDrawingBrush.width = 5
      canvas.freeDrawingBrush.color = "#202020"
      canvas.selection = false
      canvas.isDrawingMode = true
      canvas.on("mouse:up", (o) => {
        this.setState({
          signature_error: false,
          canvas_lines: canvas.getObjects(),
        })
        this.props.updateForm("canvas_lines", canvas.getObjects())
      })
      this.setState({ canvas })
    } else if (this.state.canvas && this.state.selected_form !== "parents") {
      this.setState({ canvas: undefined })
    }
    brothers.forEach((brother, i) => {
      if (
        brother.exposure_aproval !==
        PERMISSIONS["EXPOSURE_APPROVAL1_ARR_SPECIAL"]
      ) {
        this.props.updateForm("exposure_aproval_options", [], i)
      }
    })
  }

  componentDidMount() {
    const { brothers } = this.props
    const { parents_info } = this.state
    let data = parents_data.filter((m) => m.required)
    data.forEach((val) => {
      let test = brothers[0][val.state_name]
      parents_info[val.state_name] = test
      this.setState({
        parents_info,
      })
    })
  }

  async revert() {
    const { canvas } = this.state
    canvas.clear()
    this.setState({ canvas_lines: [] })
  }

  updateBrothers = (name, value) => {
    console.log(name, value)
    const { parents_info } = this.state
    parents_info[name] = value
    this.setState({
      parents_info,
    })
    this.props.updateAllBrothers(name, value)
  }

  validate = async () => {
    await new Promise((resolve) => {
      setTimeout(() => resolve(), 300)
    })
    const { values, parents_approval_form } = this.props
    const { canvas_lines } = this.state
    let error_count = 0
    const { parents_info, parents_errors } = this.state
    const { brothers } = this.props
    let data = parents_data.filter((m) => m.required)
    data.forEach((val, i) => {
      if (!parents_info[val.state_name]) {
        error_count++
        parents_errors[`${val.state_name}_err`] = true
        this.setState({
          parents_errors,
        })
      }
    })
    console.log(error_count, brothers)
    if (!values["canvas_lines" || !canvas_lines]) {
      this.setState({ signature_error: true })
    }
    if (
      (error_count === 0 && values["canvas_lines"]) ||
      (error_count === 0 && canvas_lines)
    ) {
      if (parents_approval_form) {
        this.setState({ loading: true })
      }
      console.log(this.state.canvas, 'helloasdkal')
      // const file = dataURItoBlob(this.state.canvas.toDataourURL("image/png"))
      // this.setState({ loading: true })
      // const formData = new FormData()
      // formData.append("signature", file)
      // let ids = brothers.map((m) => m.member_id_number)
      // formData.append("member_id_number", ids)
      // const res = await axios.post(`${API}/files/signaturoure`, formData)
      const error = document.querySelectorAll(".register_our_error")
      let active_errors = ""
      if (error && error.length > 0) {
        error.forEach((val) => {
          if (val.innerHTML.length > 0) {
            active_errors = true
          }
        })
      }
      if (!active_errors) {
        if (parents_approval_form) {
          let { brothers } = this.props
          brothers.forEach((val) => {
            if (val.member_position_additional) {
              val.member_position = val.member_position_additional
            }
          })
          axios.post(`${API}/bin/create-bin`, {
            bin: brothers,
            type: "parents-approval",
          })
          const res = await axios.post(`${API}/member/create?paf=1`, brothers)
          this.props.removeEventListener()
          if (res.data.ok)
            window.location.replace(
              "/registration-success?parents_approval=true"
            )
        } else {
          this.props.setForm("payment")
        }
      } else this.setState({ loading: false })
    }
  }

  setError = (name, val) => {
    this.props.setForm("parents", ["personal", "permissions", "parents"])

    const { parents_errors } = this.state
    parents_errors[`${name}_err`] = val
    this.setState({
      parents_errors,
    })
  }
  render() {
    const { windowWidth, signature_error, parents_errors, loading } = this.state

    const { values, PARENTS, PERSONAL, parents_approval_form } = this.props

    return (
      <div className="register__parents">
        <div className="register__parents__flex">
          <section>
            <header className="register__header">{PARENTS.PARENT1_H1}</header>
            <div className="parents__register__flex">
              {parents_data
                .filter((m) => m.section === "parent1")
                .map((m, i) =>
                  m.type === "number" ? (
                    <NumberInput
                      name={PARENTS[m.name]}
                      updateForm={this.updateBrothers}
                      value={values[m.value] ? values[m.value] : ""}
                      state_name={m.state_name}
                      validation={m.validation}
                      validation_error={PERSONAL[m.validation_error]}
                      error={parents_errors[`${m.value}_err`]}
                      error_msg={PARENTS[m.error_msg]}
                      setError={this.setError}
                    />
                  ) : m.type === "input_select" ? (
                    <InputSelect
                      name={PARENTS[m.name]}
                      options={PARENTS[m.options] ? PARENTS[m.options] : []}
                      updateForm={this.updateBrothers}
                      value={values[m.value] ? values[m.value] : ""}
                      state_name={m.state_name}
                      filter_value={
                        values[m.filter_value] ? values[m.filter_value] : ""
                      }
                      filter_value_state_name={m.filter_value_state_name}
                      error={parents_errors[`${m.value}_err`]}
                      error_msg={PARENTS[m.error_msg]}
                      setError={this.setError}
                    />
                  ) : m.type === "text" ? (
                    <RegularInput
                      name={PARENTS[m.name]}
                      updateForm={this.updateBrothers}
                      value={values[m.value] ? values[m.value] : ""}
                      state_name={m.state_name}
                      error={parents_errors[`${m.value}_err`]}
                      error_msg={PARENTS[m.error_msg]}
                      setError={this.setError}
                      validation={m.validation}
                      validation_error={PERSONAL[m.validation_error]}
                    />
                  ) : (
                    ""
                  )
                )}
            </div>
          </section>
          <section>
            <header className="register__header">{PARENTS.PARENT2_H1}</header>
            <div className="parents__register__flex">
              {parents_data
                .filter((m) => m.section === "parent2")
                .map((m, i) =>
                  m.type === "number" ? (
                    <NumberInput
                      name={PARENTS[m.name]}
                      updateForm={this.updateBrothers}
                      value={values[m.value] ? values[m.value] : ""}
                      state_name={m.state_name}
                      validation={m.validation}
                      validation_error={PERSONAL[m.validation_error]}
                      error={parents_errors[`${m.value}_err`]}
                      error_msg={PARENTS[m.error_msg]}
                      setError={this.setError}
                    />
                  ) : m.type === "input_select" ? (
                    <InputSelect
                      name={PARENTS[m.name]}
                      options={PARENTS[m.options] ? PARENTS[m.options] : []}
                      updateForm={this.updateBrothers}
                      value={values[m.value] ? values[m.value] : ""}
                      state_name={m.state_name}
                      filter_value={
                        values[m.filter_value] ? values[m.filter_value] : ""
                      }
                      filter_value_state_name={m.filter_value_state_name}
                      error={parents_errors[`${m.value}_err`]}
                      error_msg={PARENTS[m.error_msg]}
                      setError={this.setError}
                    />
                  ) : m.type === "text" ? (
                    <RegularInput
                      name={PARENTS[m.name]}
                      no_number={m.no_number}
                      updateForm={this.updateBrothers}
                      value={values[m.value] ? values[m.value] : ""}
                      state_name={m.state_name}
                      validation={m.validation}
                      validation_error={PERSONAL[m.validation_error]}
                      error={
                        values[m.value] ? parents_errors[`${m.value}_err`] : ""
                      }
                      error_msg={PARENTS[m.error_msg]}
                      setError={this.setError}
                    />
                  ) : (
                    ""
                  )
                )}
            </div>
          </section>
          <section>
            <header className="register__header">{PARENTS.SIGN}</header>
            {parents_data
              .filter((m) => m.section === "parent_approved")
              .map((m, i) =>
                m.type === "text" ? (
                  <RegularInput
                    name={PARENTS[m.name]}
                    updateForm={this.updateBrothers}
                    value={values[m.value] ? values[m.value] : ""}
                    state_name={m.state_name}
                    error={parents_errors[`${m.value}_err`]}
                    error_msg={PARENTS[m.error_msg]}
                    setError={this.setError}
                    validation={m.validation}
                    validation_error={PERSONAL[m.validation_error]}
                  />
                ) : m.type === "checkbox_true" ? (
                  <CheckBoxTrueFalse
                    key={i}
                    name={PARENTS[m.name]}
                    not_required={m.required}
                    placeholder={PARENTS[m.placeholder]}
                    state_name={m.state_name}
                    value={values[m.value] ? values[m.value] : ""}
                    error_msg={PARENTS[m.error_msg]}
                    updateForm={this.updateBrothers}
                    setError={this.setError}
                    error={parents_errors[`${m.value}_err`]}
                    text={PARENTS[m.text]}
                    text2={PARENTS[m.text2]}
                    text3={PARENTS[m.text3]}
                    link={m.link}
                    with_link={m.with_link}
                  />
                ) : m.type === "number" ? (
                  <NumberInput
                    name={PARENTS[m.name]}
                    updateForm={this.updateBrothers}
                    value={values[m.value] ? values[m.value] : ""}
                    state_name={m.state_name}
                    validation={m.validation}
                    validation_error={PERSONAL[m.validation_error]}
                    error={parents_errors[`${m.value}_err`]}
                    error_msg={PARENTS[m.error_msg]}
                    setError={this.setError}
                  />
                ) : m.type === "text_p" ? (
                  <span className="parents__link">
                    <a href={m.link} target="_blank" rel="noopener noreferrer">
                      <h4>{PARENTS[m.text]}</h4>
                      <img src={LinkImg} alt="" />
                    </a>
                  </span>
                ) : m.type == "signature" ? (
                  <span>
                    <div
                      style={{
                        width:
                          windowWidth > 580
                            ? "500px"
                            : `${windowWidth / 1.085}px`,
                      }}
                      className="signature__fabric__container"
                    >
                      <canvas
                        width={windowWidth > 580 ? 500 : windowWidth / 1.085}
                        height={200}
                        className="canvas"
                        id="fabric__canvas__signature"
                      />

                      <div className="signature__line" />
                      <p onClick={() => this.revert()}>איפוס חתימה</p>
                    </div>

                    {signature_error ? (
                      <div className="register__form__area">
                        <p
                          style={{
                            fontSize: "18px",
                            paddingTop: "10px",
                            marginTop: "10px",
                          }}
                        >
                          {PARENTS[m.error_msg]}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                ) : (
                  ""
                )
              )}
          </section>
          {loading ? (
            <div className="register__new__loader">
              <Loader />
            </div>
          ) : (
            <button
              onClick={() => {
                this.validate()
              }}
              className="register__submit"
            >
              <h4> {parents_approval_form ? PARENTS.FINISH : PARENTS.NEXT}</h4>
              <h3>←</h3>
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default Parents
