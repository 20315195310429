import React, { Component } from 'react'
import { permissions_data } from '../data/permissions'
import CheckBoxTrueFalse from '../parts/CheckBoxTrueFalse'
import PermissionsHealth from './permissions_parts/PermissionsHealth'
import PermissionExposure from './permissions_parts/PermisisonExposure'
import RadioInput from '../parts/RadioInput'
import PermissionsSpecialNeeds from './permissions_parts/PermissionsSpecialNeeds'
function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
}




class Permissions extends Component {
    constructor() {
        super()
        this.state = {
            brothers_status: [
                {
                    errors: {},
                    loaded: {}
                }
            ],
            updating_all: true

        }
    }



    validate = async () => {
        const { brothers } = this.props
        let error_count = 0
        let test
        let current_error = 0
        let other_error = 0
        this.setState({ other_brother_error: false })
        brothers.forEach((brother, brothers_index) => {
            const { loaded } = this.state.brothers_status[brothers_index]
            const loaded_keys = Object.keys(loaded)
            console.log(loaded_keys, 'hellos')
            loaded_keys.map((key) => {

                if (!loaded[key]) delete loaded[key]
            })
            loaded_keys.map(n => {
                if (!brother[n] || brother[n].length === 0) {
                    if (permissions_data.filter(y => y.value === n)[0].not_required) {

                    } else {
                        console.log(n, 'error')
                        error_count++
                        this.setError(n, true, brothers_index)
                        if (this.props.index === brothers_index) current_error++
                        else other_error++
                    }
                } else {

                    this.setError(n, false, brothers_index)
                }
            })
        })
        console.log(error_count)
        if (error_count === 0) {
         
            this.props.setForm('parents')
        } else {
            if (other_error > 0 && current_error === 0) this.setState({ other_brother_error: true })
        }
    }

    setError = (name, val, i) => {
        const { brothers_status } = this.state
        const { index } = this.props
        let new_errors = brothers_status
        let new_name = `${name}_err`
        new_errors[i ? i : index].errors[new_name] = val
        this.setState({
            brothers_status: new_errors
        })
        let errors = new_errors.map(val => {
            return val.errors
        })
        this.props.sendPermissionErrors(errors)
        setTimeout(() => this.checkIfFilled(), 100)
    }

    componentDidMount() {
        const { brothers } = this.props
        const brothers_status = brothers.map(n => { return { errors: {}, loaded: {} } })
        setTimeout(() => {
            this.setState({
                brothers_status,
                mounted: true,
            })
        }, 100);
        setTimeout(() => {
            this.setState({
                updating_all: false
            })
        }, 300);
    }

    updateAndCheck = (name, value) => {
        this.props.updateForm(name, value)
        setTimeout(() => this.checkIfFilled(), 100)
    }

    updateStateOnLoad = (name, value) => {
        const { updating_all, brothers_status } = this.state
        const { index, brothers } = this.props
        let new_loaded = brothers_status
        let new_name = `${name}`
        if (updating_all) {
            brothers.map((val, i) => {
                new_loaded[i].loaded[new_name] = value
            })
        } else {
            new_loaded[index].loaded[new_name] = value
        }
        this.setState({
            brothers_status: new_loaded
        })
        this.checkIfFilled()
    }

    checkIfFilled = () => {
        const { index, brothers } = this.props
        let error = 0
        const { brothers_status } = this.state
        const { loaded } = brothers_status[index]
        let keys = Object.keys(brothers_status[index].loaded)
        keys.map(n => {
            if (!loaded[n]) delete loaded[n]
        })
        keys.forEach(val => {
            if (!brothers[index][val] && !val.includes('special_communication_ways')) {
                error = error + 1
                setTimeout(() => this.props.setForm(this.props.currentForm, ['personal', 'permissions']), 150)
            }
        })
        this.props.setBrotherProgress(error, index)
    }

    render() {
        const {
            brothers_status,
            mounted,
            other_brother_error
        } = this.state
        const {
            values,
            index,
            language,
            brothers
        } = this.props
        const { PERMISSIONS } = language
      
        return (
            mounted && values ?
                <div className='permissions__wrapper'>
                    <div className='permission__register__flex'>
                        <section>
                            <header
                                className='register__header'>צרכים ייחודיים</header>
                            <PermissionsSpecialNeeds
                                language={language}
                                updateForm={this.updateAndCheck}
                                setError={this.setError}
                                parents={true}
                                data={permissions_data}
                                values={values}
                                checked_value={values['health_declaration']}
                                setError={this.setError}
                                errors={brothers_status[index].errors}
                                updateStateOnLoad={this.updateStateOnLoad}
                            />
                        </section>
                        <section>
                            <header
                                className='register__header'>{PERMISSIONS.DISABILITY_H1}</header>
                            <PermissionsHealth
                                language={language}
                                updateForm={this.updateAndCheck}
                                setError={this.setError}
                                parents={true}
                                data={permissions_data}
                                values={values}
                                checked_value={values['health_declaration']}
                                setError={this.setError}
                                errors={brothers_status[index].errors}
                                updateStateOnLoad={this.updateStateOnLoad}
                            />
                        </section>
                        <section className='permission__parents'>
                            <header className='register__header'>{PERMISSIONS.PARENTS_APPROVAL_H1}</header>
                            {
                                permissions_data && permissions_data.length > 0 ?
                                    permissions_data.filter(m => m.section === 'parents_approvals').map((m, i) =>
                                        m.type === 'checkbox_true' ?

                                            <CheckBoxTrueFalse
                                                key={i}
                                                not_required={m.not_required}
                                                name={PERMISSIONS[m.name]}
                                                placeholder={PERMISSIONS[m.placeholder]}
                                                state_name={m.state_name}
                                                value={values[m.value] ? values[m.value] : ''}
                                                error_msg={PERMISSIONS[m.error_msg]}
                                                updateForm={this.updateAndCheck}
                                                setError={this.setError}
                                                dont_display={m.dont_display}
                                                error={brothers_status[index].errors[`${m.value}_err`]}
                                                updateStateOnLoad={this.updateStateOnLoad}
                                                permission={true}
                                            />
                                            :
                                            m.type === 'p_text_bold' ?
                                                <span>
                                                    <p><span style={{ fontWeight: 'bold' }}>{PERMISSIONS[m.text_bold]}</span>{PERMISSIONS[m.text]}</p>
                                                </span>

                                                : ''
                                    )
                                    : ''
                            }

                        </section>
                        <section>
                            <header className='register__header'>{PERMISSIONS.EXPOSURE_H1}</header>
                            <PermissionExposure
                                updateForm={this.updateAndCheck}
                                setError={this.setError}
                                parents={true}
                                errors={brothers_status[index].errors}
                                data={permissions_data}
                                values={values}
                                PERMISSIONS={PERMISSIONS}
                                setError={this.setError}
                                updateStateOnLoad={this.updateStateOnLoad}

                            />
                        </section>
                        <section className='ride__permission'>
                            <header className='register__header'>{PERMISSIONS.RIDE_PERMISSION_H1}</header>
                            {
                                permissions_data && permissions_data.length > 0 ?
                                    permissions_data.filter(m => m.section === 'ride_permission').map((m, i) =>
                                        m.type === 'p_text' ?
                                            <p>{PERMISSIONS[m.text]}</p> :

                                            m.type === 'checkbox_true' && !m.optional ?
                                                <CheckBoxTrueFalse
                                                    key={i}
                                                    name={PERMISSIONS[m.name]}
                                                    not_required={m.not_required}
                                                    placeholder={PERMISSIONS[m.placeholder]}
                                                    state_name={m.state_name}
                                                    value={values[m.value] ? values[m.value] : ''}
                                                    error_msg={PERMISSIONS[m.error_msg]}
                                                    updateForm={this.updateAndCheck}
                                                    setError={this.setError}
                                                    error={brothers_status[index].errors[`${m.value}_err`]}
                                                    updateStateOnLoad={this.updateStateOnLoad}
                                                    permission={true}
                                                />
                                                :
                                                PERMISSIONS.RIDE_OPTIONS.includes(values['grade']) &&
                                                    m.type === 'radio' &&
                                                    m.optional ?
                                                    <RadioInput
                                                        key={i}
                                                        name={PERMISSIONS[m.name]}
                                                        options={PERMISSIONS[m.options]}
                                                        placeholder={PERMISSIONS[m.placeholder]}
                                                        state_name={m.state_name}
                                                        value={values[m.value] ? values[m.value] : ''}
                                                        error_msg={PERMISSIONS[m.error_msg]}
                                                        selected={m.selected}
                                                        error={brothers_status[index].errors[`${m.value}_err`]}
                                                        updateForm={this.updateAndCheck}
                                                        setError={this.setError}
                                                        updateStateOnLoad={this.updateStateOnLoad}
                                                        permission={true}
                                                    />

                                                    :
                                                    PERMISSIONS.RIDE_OPTIONS.includes(values['grade']) &&
                                                        m.type === 'checkbox_true' && m.optional ?
                                                        <CheckBoxTrueFalse
                                                            key={i}
                                                            name={PERMISSIONS[m.name]}
                                                            not_required={m.not_required}
                                                            placeholder={PERMISSIONS[m.placeholder]}
                                                            state_name={m.state_name}
                                                            value={values[m.value] ? values[m.value] : ''}
                                                            error_msg={PERMISSIONS[m.error_msg]}
                                                            updateForm={this.updateAndCheck}
                                                            error={brothers_status[index].errors[`${m.value}_err`]}
                                                            setError={this.setError}
                                                            updateStateOnLoad={this.updateStateOnLoad}
                                                            permission={true}
                                                        />

                                                        : ''
                                    ) : ''
                            }
                        </section>
                        {
                            other_brother_error
                                ?
                                <p className="borther__permissions__error">
                                    יש להשלים פרטים עבור כל האחים כדי להמשיך
                        </p>
                                :
                                ''
                        }
                        <button
                            className='register__submit'
                            onClick={() => this.validate()}>
                            <h4> {PERMISSIONS.NEXT}</h4>
                            <h3>←</h3>
                        </button>
                    </div>
                </div> : ''
        )
    }
}

export default Permissions