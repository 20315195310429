import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox';

class CheckBoxTrueFalse extends Component {
    constructor() {
        super()
        this.state = {
            checked: false
        }
    }

    handleChange = (val) => {
        const { state_name } = this.props
        this.setState({
            checked: val
        })
        this.props.updateForm(state_name, val)
        if(!val){
            if(!this.props.not_required)
            this.props.setError(state_name, true)
        }else{
            this.props.setError(state_name, false)
        }
    }

    async componentDidMount() {
        const { permission, state_name, value } = this.props
        if (permission) {
            await this.props.updateStateOnLoad(state_name, true)
        }
    }

    componentWillUnmount() {
        const { state_name, permission } = this.props
        this.props.setError(state_name, false)
        if (permission) {
            this.props.updateStateOnLoad(state_name, false)
        }
    }

    componentWillReceiveProps(nextProps){
        const {required} = this.props
  
    }

    render() {
        const { section, name, value, data_to_render, extra_class, error_msg, state_name, error, with_link , text, text2,  text3, link, dont_display} = this.props
        return (
            dont_display ? '' :
            <div className={extra_class ?
                `register__checkbox__${extra_class} 
            register__checkbox`  :
                'register__checkbox'}>
                <aside className='checkbox__flex'>
                    <span>
                        <figure>
                            <Checkbox
                                onFocus={() => this.props.setError(state_name, false)}
                                checked={value}
                                onChange={() => this.handleChange(value ? false : true)}
                            />

                        </figure>
                    </span>
                   {with_link ? <h3>{text} <a href={link} target="_blank" rel="noopener noreferrer" >{text2}</a>{text3}</h3> :  <h3>{name}</h3>}
                    {error ? <p className='register__error'>{error_msg}</p> : ''}
                </aside>
                {value&& section
                    ?
                    data_to_render 
                    : ''
                }
            </div>
        )
    }
}

export default CheckBoxTrueFalse